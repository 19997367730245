<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="shower"
        max-width="520px"
        persistent
        scrollable
        v-if="resolutionScreen >= 500"
      >
        <!-- <v-overlay :value="!loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress"></span>
        </v-progress-circular>
        </v-overlay> -->
        <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template>-->
        <v-card scrollable persistent>
          <v-card-title>
            <v-layout>
              <v-flex lg11 v-if="resolutionScreen >= 400">
                {{ $t("sharefile.messageboxheader") }}
              </v-flex>
              <v-flex lg11 xs10 v-else>
                {{ $t("sharefile.messageboxheader").substring(0, 20) }}
                <!-- {{ $t("sharefile.messageboxheader").substring(0, 20) + "..." }} -->
              </v-flex>
              <v-flex lg1 xs2 class="text-right"
                ><v-icon :color="color.theme" dense dark size="35"
                  >group_add</v-icon
                ></v-flex
              >
            </v-layout>
            <!-- {{ $t("sharefile.messageboxheader") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">group_add</v-icon> -->
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="height:440px" class="pt-4">
            <v-tabs v-model="opentabs" mobile-breakpoint class="elevation-2">
              <v-tab @click="opentabs = 0" class="pt-2">
                <!-- <v-badge
                  color="error"
                  :content="message.length"
                  v-if="message.length > 0"
                  >{{ $t("sharefile.personal") }}</v-badge
                >
                <div v-else>{{ $t("sharefile.personal") }}</div> -->
                <v-badge
                  color="error"
                  :content="listgroup_data.length"
                  v-if="listgroup_data.length > 0"
                  >{{ $t("sharefile.personal") }}</v-badge
                >
                <div v-else>{{ $t("sharefile.personal") }}</div>
                <!-- <div v-else>{{$t('sharefile.Email')}}</div> -->
              </v-tab>
              <!-- <v-tab @click="(opentabs = 1)">
                      <v-badge color="error" :content="messagetax.length" v-if="messagetax.length > 0"
                        >เลขประจำตัวผู้เสียภาษี (องค์กร)</v-badge
                      >
                      <v-div v-else>เลขประจำตัวผู้เสียภาษี (องค์กร)</v-div>
                    </v-tab> -->
              <v-tab @click="opentabs = 1" class="pt-2">
                <v-badge
                  color="error"
                  :content="messagetax.length"
                  v-if="messagetax.length > 0"
                >
                  {{ $t("sharefile.taxidbusiness") }}
                </v-badge>
                <div v-else>{{ $t("sharefile.taxidbusiness") }}</div>
              </v-tab>
              <v-tab-item>
                <v-container fluid>
                  <v-layout row wrap justify-center>
                    <v-flex lg4 md4 xs4 class="text-center mt-6 mr-1">
                      <v-select
                        outlined
                        dense
                        v-model="typeshare"
                        :items="listtypeshare"
                        item-value="listtypeshare_value"
                        :item-text="
                          $t('default') === 'th'
                            ? 'listtypeshare_type'
                            : 'listtypeshare_type_eng'
                        "
                        :label="$t('sharefile.type')"
                      ></v-select>
                    </v-flex>
                    <v-flex lg7 md7 xs7 class="text-center mt-6 pl-2">
                      <!-- แชร์อีเมลแบบเก่า -->
                      <!-- <v-text-field
                            v-model="email"
                            :label="$t('sharefile.Email')"
                            persistent-hint
                            multiple
                            outlined
                            dense
                            :disabled="!loaddataprogress"
                            :color="color.theme"
                          ></v-text-field> -->
                      <!-- แชร์แบบ type -->
                      <!-- {{typeshare_data}}
                          {{typeshare}} + TEST -->
                      <v-text-field
                        outlined
                        dense
                        :disabled="typeshare === ''"
                        v-model="typeshare_data"
                        :label="
                          typeshare === 'thai_email'
                            ? 'One Email'
                            : typeshare === 'username'
                            ? 'Username'
                            : typeshare === 'one_collab_mail'
                            ? 'One Collaboration Email'
                            : $t('default') === 'th'
                            ? 'กรุณาเลือกประเภทผู้ใช้งาน'
                            : 'Please select type'
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap justify-center class="mt-n5">
                    <v-flex lg11 xs11>
                      <v-switch
                        v-model="switch1"
                        inset
                        :label="$t('sharefile.settime')"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg5 xs11 v-if="switch1 === true">
                      <v-dialog
                        persistent
                        v-model="modal"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            v-model="picker"
                            :label="$t('sharefile.setdate')"
                            readonly
                            prepend-icon="mdi-calendar"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          :min="datenow"
                          @input="modal = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="modal = false">
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                                <v-divider vertical></v-divider>
                              </v-flex> -->
                    <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true ">
                                <v-icon>access_time</v-icon>
                              </v-flex> -->
                    <v-flex lg3 xs6 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.sethours')"
                        outlined
                        dense
                        :items="hours"
                        v-model="hour"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex lg3 xs5 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.setminute')"
                        outlined
                        dense
                        :items="minutes"
                        v-model="minute"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs11 class="text-left">
                      <v-subheader class="pl-1">{{
                        $t("sharefile.setpermissionsharefile")
                      }}</v-subheader>
                    </v-flex>
                    <v-flex lg7 xs6>
                      <v-select
                        v-model="status"
                        :items="values"
                        :label="$t('sharefile.Status')"
                        outlined
                        dense
                        class="elevation-0"
                        return-object
                        :color="color.theme"
                        :item-color="color.theme"
                      >
                        <template v-slot:item="{ item }">{{
                          $t(item.text)
                        }}</template>
                        <template v-slot:selection="{ item }">{{
                          $t(item.text)
                        }}</template>
                      </v-select>
                    </v-flex>
                    <v-flex lg4 xs5 class="text-right">
                      <v-btn
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :disabled="!loaddataprogress"
                        @click="addemail()"
                      >
                        {{ $t("sharefile.share") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <!-- <v-flex lg11 xs10 v-if="message.length !== 0">
                      <v-subheader class="ml-n3 mb-n2">{{
                        $t("sharefile.sharewith")
                      }}</v-subheader>
                    </v-flex> -->
                    <v-flex lg11 xs10 v-if="listgroup_data.length !== 0">
                      <v-subheader class="ml-n3 mb-n2">{{
                        $t("sharefile.sharewith")
                      }}</v-subheader>
                    </v-flex>
                    <!-- loading -->
                    <v-progress-circular
                      v-if="loaddataprogress === false"
                      indeterminate
                      :color="color.theme"
                      size="100"
                    >
                      <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                    </v-progress-circular>
                    <!-- {{normol_mail_list[0]}} -->
                    <!-- {{listgroup_data}} -->
                    <!-- {{filedata}} -->
                    <!-- แชร์ผู้ใช้งานทั่วไป แบบใหม่ -->
                    <v-flex lg12 xs12>
                      <!-- :no-data-text="$t('tablefile.empty_resend')" -->
                      <!-- {{statusshare[0]}} -->
                      <!-- {{edit_key}} -->
                    <v-data-table
                        :headers="header"
                        :items="listgroup_data"
                        class="elevation-0"
                        :no-data-text="$t('sharefile.sharing_not_found')"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.type`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.name`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <template v-slot:[`header.status`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template>
                        <!-- <template v-slot:[`header.edit`]="{ header }">
                          <span :style="headerTable">{{$t(header.text)}}</span>
                        </template> -->
                        <template v-slot:item="props">
                          <tr class="pointer" style="cursor:pointer;" @click="edit_sharefile(props.item)">
                            <!-- ไอคอนประเภทผู้ใช้งาน -->
                            <td class="text-center" width="10%">
                            <v-icon v-if="props.item.group_mail.type_shared === '0'">mdi-account</v-icon>
                            <v-icon v-else-if="props.item.group_mail.type_shared === '1'">mdi-email-outline</v-icon>
                            <v-icon v-else-if="props.item.group_mail.type_shared === '2'">mdi-email</v-icon>
                            </td>
                            <!-- ชื่อผู้ใช้งาน -->
                            <td v-if="$t('default') === 'th'" class="text-center" width="60%">{{ props.item.group_mail.name_th}}</td>
                            <td v-else class="text-center" width="60%">{{ props.item.group_mail.name_eng}}</td>
                            <!-- สถานะ -->
                            <td width="20%" class="text-center" v-if="props.item.group_mail.status === 'V'">{{$t('sharefile.view')}}</td>
                            <td width="20%" class="text-center" v-else-if="props.item.group_mail.status === 'DO'">{{$t('sharefile.download')}}</td>
                            <td width="20%" class="text-center" v-else-if="props.item.group_mail.status === 'U'">{{$t('sharefile.upload')}}</td>
                            <td width="20%" class="text-center" v-else-if="props.item.group_mail.status === 'E'">{{$t('sharefile.edit')}}</td>
                            <td width="20%" class="text-center" v-else-if="props.item.group_mail.status === 'De'">{{$t('sharefile.delete')}}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <template>
                      <v-row justify="center">
                        <v-dialog
                          v-model="sharefile_setting"
                          width="600px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="text-h">{{$t('sharefile.share_detail')}}</span>
                              <v-flex class="d-flex justify-end">
                              <v-btn
                                large
                                color="grey"
                                icon
                                @click="sharefile_setting = false , edit_detail = false, loaddata(), switch_edit = false"
                              >
                                <v-icon>
                                  mdi-close-circle
                                </v-icon>
                              </v-btn>
                              </v-flex>
                            </v-card-title>
                            <v-divider></v-divider>
                            <!-- :prepend-icon="list_sharefile.group_mail.type_shared === '0' ? 'mdi-account' : list_sharefile.group_mail.type_shared === '1' ? 'mdi-email-outline' : 'mdi-email'" -->
                            <v-card-text>
                              <v-layout justify-end class="mt-5">
                                <!-- {{edit_detail}} -->
                              </v-layout>                           

                              <v-flex lg12 md12 sm12 xs12>
                                <div class="d-flex">
                                  <div class="mt-5 align-self-center" outlined tile>
                                    <v-icon>
                                      mdi-file-edit
                                    </v-icon>
                                  </div>
                                  <div class="pa-2">
                                    <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.file_name')+' '+':'}}</p>
                                    <p style="font-size:16px;margin-bottom:0px">{{filedata.file_name}}</p>
                                  </div>
                                </div>
                                <div class="d-flex mb-3">
                                  <div class="mt-5 align-self-center" outlined tile>
                                    <v-icon v-if="sharefile_type_shared === '0'">mdi-account</v-icon>
                                    <v-icon v-else-if="sharefile_type_shared === '1'">mdi-email-outline</v-icon>
                                    <v-icon v-else-if="sharefile_type_shared === '2'">mdi-email</v-icon>
                                  </div>
                                  <div class="pa-2">
                                    <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.name')+' '+':'}}</p>
                                    <p v-if="$t('default') === 'th'" style="font-size:16px;margin-bottom:0px">{{sharefile_name_th}}</p>
                                    <p v-else style="font-size:16px;margin-bottom:0px">{{sharefile_name_eng}}</p>
                                  </div>
                                </div>
                              <v-layout
                                row
                                wrap
                                justify-left
                                pa-1
                                v-for="(item, i) in edit_status_sharefile"
                                :key="i"
                                class="mb-n4"
                              >
                                <!-- <v-flex lg12 xs12 class=" mb-6">
                                  <v-divider></v-divider>
                                </v-flex> -->
                                <!-- สถานะ -->
                                <v-flex lg8 md8 sm8 xs8 class="mb-3">
                                  <v-select
                                    prepend-icon="mdi-map-marker"
                                    class="pl-2"
                                    :label="$t('sharefile.Status')"
                                    hide-details
                                    v-model="statusshare[index_status]"
                                    :items="values"
                                    outlined
                                    dense
                                    return-object
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  >
                                    <template v-slot:item="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                    <template v-slot:selection="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                  </v-select>
                                </v-flex>
                                <!-- ปุ่มเปิดวันที่และเวลา -->
                                <!-- {{switch_edit}} -->
                                  <!-- <v-flex lg11 xs11 class="ml-9">
                                    <v-switch
                                      v-model="switch_edit"
                                      inset
                                      :label="$t('sharefile.settime')"
                                    ></v-switch>
                                  </v-flex> -->
                                
                                <!-- วันที่ -->
                                <v-flex lg8 md8 sm8 xs8 class="mb-n3 mt-2">
                                  <v-dialog
                                    persistent
                                    v-model="emailformdate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <!-- {{picker2}} -->
                                      <!-- {{item.group_mail.number_of_days}} -->
                                      <!-- {{picker2[index_status]}} -->
                                      <v-text-field
                                        clearable
                                        @click:clear="picker2[index_status] = ''"
                                        :label="picker2[index_status] === '' || null ? $t('sharefile.no_date_set') : $t('sharefile.setdate')"
                                        :value="picker2[index_status] === '' || null ? setdate(item.group_mail.number_of_days) : formatdatetextfield(picker2[index_status])"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="pl-2 pr-2"
                                        @click="checkpicker = index_status"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="picker2[checkpicker]"
                                      :min="datenow"
                                      @input="emailformdate = false"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click="emailformdate = false"
                                      >
                                        {{ $t("sharefile.closedatepiker") }}
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- เวลา -->
                                <v-layout>
                                <v-flex lg4 md4 sm4 xs4 class="mt-2" >
                                  <!-- {{hour2[index_status]}} -->
                                  <v-select
                                    prepend-icon="mdi-timer"
                                    :label="$t('sharefile.sethours')"
                                    class="pl-2 pr-1"
                                    :items="hours"
                                    v-model="hour2[index_status]"
                                    outlined
                                    dense
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex lg4 md4 sm4 xs4 class="mt-2">
                                  <!-- {{minute2[index_status]}} -->
                                  <v-select
                                    class=" pr-2"
                                    :label="$t('sharefile.setminute')"
                                    :items="minutes"
                                    v-model="minute2[index_status]"
                                    dense
                                    outlined
                                  >
                                  </v-select>
                                </v-flex>
                                </v-layout>
                                <!-- ยกเลิกการแชร์ -->
                                <!-- <v-flex lg12 xs12 class="pt-2 ml-8 text-center"> -->
                                  <!-- {{openconfirmcancelusersharefile}} -->
                                  <v-layout style="justify-content: center;" justify-center>
                                  <v-btn
                                    :color="color.theme"
                                    class="white--text mr-2"
                                    :disabled="checkbuttonupdate"
                                    @click="edit_share_detail(),(edit_detail = false)"
                                    :loading="processloading"
                                  >
                                    <v-icon left size="18">
                                    mdi-floppy
                                    </v-icon>
                                    {{$t("sharefile.save")}}
                                  </v-btn>
                                  <v-btn
                                  color="red"
                                  dark
                                  @click="(itemdelete = item.group_mail),(openconfirmcancelusersharefile = true)"
                                  >
                                    <v-icon
                                      left size="18"
                                    >
                                      mdi-share-off
                                    </v-icon>
                                    {{$t("sharefile.cancel_sharing")}}
                                  </v-btn>
                                  </v-layout>
                                <!-- </v-flex> -->
                              </v-layout>
                              </v-flex>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </template>
                    <!-- Share with -->
                    <!-- แบบเก่า -->
                    <!-- <v-flex lg11 xs11>
                      <v-layout
                        row
                        wrap
                        justify-left
                        pa-1
                        v-for="(item, i) in listgroup_data"
                        :key="i"
                        class="mb-n4"
                      >
                        <v-flex lg12 xs12 class=" mb-6">
                          <v-divider></v-divider>
                        </v-flex>

                        <v-flex lg6 xs10 class="mb-3">
                          <v-text-field
                            :label="item.group_mail.type_shared === '0' ? 'Username' : item.group_mail.type_shared === '1' ? 'One Email' : 'One Collaboration Email'"
                            outlined
                            readonly
                            dense
                            v-model="item.group_mail.name_th"
                            hide-details
                            :color="color.theme"
                          ></v-text-field>
                        </v-flex>
                        
                        <v-flex
                          lg1
                          xs2
                          class="pt-2 text-left"
                          v-if="item.group_mail.type_shared === '3'"
                        >
                          <v-btn
                            class="elevation-0"
                            fab
                            x-small
                            v-model="item.group_mail.name_th"
                            @click="detail_group = false"
                            v-if="detail_group == true"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="elevation-0"
                            fab
                            x-small
                            v-model="item.group_mail.name_th"
                            @click="detail_group = true"
                            v-else
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex lg4 xs10 class="mb-3">
                          <v-select
                            class="pl-2"
                            :label="$t('sharefile.Status')"
                            hide-details
                            v-model="statusshare[i]"
                            :items="values"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex>
                        
                        <v-flex lg1 xs2 class="pt-2 text-center">

                          <v-icon
                            color="red"
                            @click="
                              (itemdelete = item.group_mail),
                                (openconfirmcancelusersharefile = true)
                            "
                            >delete</v-icon
                          >
                        </v-flex>

                        <v-layout
                          row
                          wrap
                          justify-left
                          pa-1
                          class="mb-n4 ma-1"
                          v-if="detail_group == true"
                        >
                          <v-list>
                            <v-list-item
                              v-for="(itemm, i) in item.mail_list"
                              :key="i"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="itemm.name_th"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-layout>

                        <v-flex lg6 xs12 class="mb-n3 mt-2">
                          <v-dialog
                            persistent
                            v-model="emailformdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                clearable
                                :label="$t('sharefile.setdate')"
                                :value="
                                  picker2[i] === '' || null
                                    ? setdate(item.group_mail.number_of_days)
                                    : formatdatetextfield(picker2[i])
                                "
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                class="pl-2 pr-2"
                                @click="checkpicker = i"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="picker2[checkpicker]"
                              :min="datenow"
                              @input="emailformdate = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="emailformdate = false"
                              >
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                        
                        <v-flex lg3 xs6 class="mt-2">
                          <v-select
                            :label="$t('sharefile.sethours')"
                            class="pl-2 pr-1"
                            :items="hours"
                            v-model="hour2[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs6 class="mt-2">
                          <v-select
                            class=" pr-2"
                            :label="$t('sharefile.setminute')"
                            :items="minutes"
                            v-model="minute2[i]"
                            dense
                            outlined
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <!-- {{opentabs}} -->
                <v-container fluid>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs11 class="text-center mt-6">
                      <v-text-field
                        v-model="taxid"
                        :disabled="!loaddataprogress"
                        :label="$t('TaxID')"
                        persistent-hint
                        multiple
                        outlined
                        dense
                        :color="color.theme"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center class="mt-n5">
                    <v-flex lg11 xs11>
                      <v-switch
                        v-model="switch1"
                        inset
                        :label="$t('sharefile.settime')"
                      >
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg5 xs11 v-if="switch1 === true">
                      <v-dialog
                        persistent
                        v-model="modal2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            v-model="picker"
                            :label="$t('sharefile.setdate')"
                            readonly
                            prepend-icon="mdi-calendar"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          :min="datenow"
                          @input="modal2 = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="modal2 = false">
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                                <v-divider vertical></v-divider>
                              </v-flex> -->
                    <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true">
                                <v-icon>access_time</v-icon>
                              </v-flex> -->
                    <v-flex lg3 xs6 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.sethours')"
                        outlined
                        dense
                        :items="hours"
                        v-model="hour"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex lg3 xs5 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.setminute')"
                        outlined
                        dense
                        :items="minutes"
                        v-model="minute"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs11 class="text-left mb-2">
                      <v-subheader class="pl-1">{{
                        $t("sharefile.setpermissionsharefile")
                      }}</v-subheader>
                    </v-flex>
                    <v-flex lg7 xs6>
                      <v-select
                        v-model="status"
                        :items="values"
                        :label="$t('sharefile.Status')"
                        text
                        dense
                        return-object
                        :color="color.theme"
                        :item-color="color.theme"
                        outlined
                      >
                        <template v-slot:item="{ item }">{{
                          $t(item.text)
                        }}</template>
                        <template v-slot:selection="{ item }">{{
                          $t(item.text)
                        }}</template>
                      </v-select>
                    </v-flex>
                    <v-flex lg4 xs5 class="text-right">
                      <v-btn
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :disabled="!loaddataprogress"
                        @click="checktaxid()"
                      >
                        {{ $t("sharefile.share") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs10 v-if="messagetax.length !== 0">
                      <v-subheader class="ml-n3 mb-n2">{{
                        $t("sharefile.sharewith")
                      }}</v-subheader>
                    </v-flex>
                    <!-- loadingtax -->
                    <v-progress-circular
                      v-if="loaddataprogress === false"
                      indeterminate
                      :color="color.theme"
                      size="100"
                    >
                      <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                    </v-progress-circular>
                      <!-- {{messagetax}} -->
                        <!-- แชร์เลขประจำตัวผู้เสียภาษี(บริษัท) แบบใหม่ -->
                        <v-flex lg12 xs12 class=" mb-6">
                          <v-data-table
                            :headers="header"
                            :items="messagetax"
                            class="elevation-0"
                            :no-data-text="$t('sharefile.sharing_not_found')"
                            :page.sync="page"
                            :items-per-page="size"
                            :hide-default-footer="true"
                            @page-count="pageCount = $event"
                          >
                            <template v-slot:[`header.type`]="{ header }">
                              <span :style="headerTable">{{$t(header.text)}}</span>
                            </template>
                            <template v-slot:[`header.name`]="{ header }">
                              <span :style="headerTable">{{$t(header.text)}}</span>
                            </template>
                            <template v-slot:[`header.status`]="{ header }">
                              <span :style="headerTable">{{$t(header.text)}}</span>
                            </template>
                            <!-- <template v-slot:[`header.edit`]="{ header }">
                              <span :style="headerTable">{{$t(header.text)}}</span>
                            </template> -->
                            <template v-slot:item="props">
                              <tr class="pointer" style="cursor:pointer;" @click="edit_sharefile_tax(props.item)">
                                <!-- ไอคอนประเภทผู้ใช้งาน -->
                                <td class="text-center" width="10%">
                                <v-icon>mdi-domain</v-icon>
                                </td>
                                <!-- ชื่อผู้ใช้งาน -->
                                <td v-if="$t('default') === 'th'" class="text-center" width="60%">{{ props.item.name_th}}</td>
                                <td v-else class="text-center" width="60%">{{ props.item.name_eng}}</td>
                                <!-- สถานะ -->
                                <td width="20%" class="text-center" v-if="props.item.status === 'V'">{{$t('sharefile.view')}}</td>
                                <td width="20%" class="text-center" v-else-if="props.item.status === 'DO'">{{$t('sharefile.download')}}</td>
                                <td width="20%" class="text-center" v-else-if="props.item.status === 'U'">{{$t('sharefile.upload')}}</td>
                                <td width="20%" class="text-center" v-else-if="props.item.status === 'E'">{{$t('sharefile.edit')}}</td>
                                <td width="20%" class="text-center" v-else-if="props.item.status === 'De' || props.item.status === 'DE'">{{$t('sharefile.delete')}}</td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-flex>
                        <template>
                          <v-row justify="center">
                            <v-dialog
                              v-model="sharefile_setting_tax"
                              width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="text-h">{{$t('sharefile.share_detail')}}</span>
                                  <v-flex class="d-flex justify-end">
                                  <v-btn
                                    large
                                    color="grey"
                                    icon
                                    @click="sharefile_setting_tax = false, edit_detail = false, loaddatataxid()"
                                  >
                                    <v-icon>
                                      mdi-close-circle
                                    </v-icon>
                                  </v-btn>
                                  </v-flex>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <v-layout justify-end class="mt-5">
                                  </v-layout>
                                  <v-flex lg12 md12 sm12 xs12>
                                    <div class="d-flex">
                                      <div class="mt-5 align-self-center" outlined tile>
                                        <v-icon>
                                          mdi-file-edit
                                        </v-icon>
                                      </div>
                                      <div class="pa-2">
                                        <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.file_name')+' '+':'}}</p>
                                        <p style="font-size:16px;margin-bottom:0px">{{filedata.file_name}}</p>
                                      </div>
                                    </div>
                                    <div class="d-flex mb-3">
                                      <div class="mt-5 align-self-center" outlined tile>
                                        <v-icon>mdi-domain</v-icon>
                                      </div>
                                      <div class="pa-2">
                                        <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.name')+' '+':'}}</p>
                                        <p v-if="$t('default') === 'th'" style="font-size:16px;margin-bottom:0px">{{sharefile_name_th_tax}}</p>
                                        <p v-else style="font-size:16px;margin-bottom:0px">{{sharefile_name_eng_tax}}</p>
                                      </div>
                                    </div>
                                    
                                  <!-- {{list_sharefile_tax}} -->
                                  <v-layout
                                    row
                                    wrap
                                    justify-left
                                    pa-1
                                    v-for="(item, i) in edit_status_sharefile_tax"
                                    :key="i"
                                    class="mb-n4"
                                  >
                                  <!-- {{statussharetax[index_status_tax]}} -->
                                  <!-- {{list_sharefile_tax}} -->
                                    <!-- สถานะ -->
                                    <v-flex lg8 md8 sm8 xs8 class="mb-3">
                                      <v-select
                                        prepend-icon="mdi-map-marker"
                                        class="pl-2"
                                        :label="$t('sharefile.Status')"
                                        hide-details
                                        v-model="statussharetax[index_status_tax]"
                                        :items="values"
                                        outlined
                                        dense
                                        return-object
                                        :color="color.theme"
                                        :item-color="color.theme"
                                      >
                                        <template v-slot:item="{ item }">{{
                                          $t(item.text)
                                        }}</template>
                                        <template v-slot:selection="{ item }">{{
                                          $t(item.text)
                                        }}</template>
                                      </v-select>
                                    </v-flex>
                                    <!-- วันที่ -->
                                    <v-flex lg8 md8 sm8 xs8 class="mb-n3 mt-2">
                                      <v-dialog
                                        persistent
                                        v-model="taxfromdate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            clearable
                                            @click:clear="picker2[index_status_tax] = ''"
                                            :label="pickertax[index_status_tax] === '' || null ? $t('sharefile.no_date_set') : $t('sharefile.setdate')"
                                            :value="pickertax[index_status_tax] === '' || null ? setdate(item.number_of_days) : formatdatetextfield(pickertax[index_status_tax])"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            class="pl-2 pr-2"
                                            @click="checkpicker = index_status_tax"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="pickertax[checkpicker]"
                                          :min="datenow"
                                          @input="taxfromdate = false"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click="taxfromdate = false"
                                          >
                                            {{ $t("sharefile.closedatepiker") }}
                                          </v-btn>
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <!-- เวลา -->
                                    <v-layout>
                                    <v-flex lg4 md4 sm4 xs4 class="mt-2">
                                      <!-- {{hourtax[index_status_tax]}} -->
                                      <v-select
                                        prepend-icon="mdi-timer"
                                        :label="$t('sharefile.sethours')"
                                        class="pl-2 pr-1"
                                        :items="hours"
                                        v-model="hourtax[index_status_tax]"
                                        outlined
                                        dense
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex lg4 md4 sm4 xs4 class="mt-2">
                                      <!-- {{minutetax[index_status_tax]}} -->
                                      <v-select
                                        class=" pr-2"
                                        :label="$t('sharefile.setminute')"
                                        :items="minutes"
                                        v-model="minutetax[index_status_tax]"
                                        dense
                                        outlined
                                      >
                                      </v-select>
                                    </v-flex>
                                    </v-layout>
                                    <!-- ยกเลิกการแชร์ -->
                                    <v-flex lg12 md12 sm12 xs12 class="pt-2 ml-8 text-center">
                                      <!-- {{openconfirmcancelusersharefile}} -->
                                      <v-layout style="justify-content: center;" justify-center>
                                      <v-btn
                                        :color="color.theme"
                                        class="white--text mr-2"
                                        :disabled="checkbuttonupdate"
                                        @click="edit_share_detail(),edit_detail = false"
                                        :loading="processloading"
                                      >
                                        <v-icon left size="18">
                                        mdi-floppy
                                        </v-icon>
                                        {{$t("sharefile.save")}}
                                      </v-btn>
                                      <v-btn
                                      color="red"
                                      dark
                                      @click="(itemdelete = item),(openconfirmcancelusersharefile = true)"
                                      >
                                        <v-icon
                                          left size="18"
                                        >
                                          mdi-share-off
                                        </v-icon>
                                        {{$t("sharefile.cancel_sharing")}}
                                      </v-btn>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                  </v-flex>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-row>
                        </template>
                    <!-- Share with tax-->
                    <!-- แบบเก่า -->
                    <!-- <v-flex lg11 xs11>
                      <v-layout
                        row
                        wrap
                        justify-center
                        pa-1
                        v-for="(item, i) in messagetax"
                        :key="i"
                        class="mb-n4"
                      >
                        <v-flex lg12 xs12 class=" mb-6">
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex lg7 xs12 class="mb-3">
                          <v-text-field
                            class="pl-2 pr-2"
                            v-if="$t('default') === 'th'"
                            v-model="item.name_th"
                            outlined
                            readonly
                            dense
                            hide-details
                            :color="color.theme"
                            :label="$t('sharefile.business')"
                          ></v-text-field>
                          <v-text-field
                            class="pl-2 pr-2"
                            v-else
                            v-model="item.name_eng"
                            outlined
                            readonly
                            dense
                            hide-details
                            :color="color.theme"
                            :label="$t('sharefile.business')"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg4 xs10 class="mb-3">
                          <v-select
                            class="pl-2"
                            hide-details
                            :items="values"
                            :label="$t('sharefile.Status')"
                            v-model="statussharetax[i]"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex>
                        <v-flex lg1 xs2 class="pt-2 text-center">
                          <v-icon
                            color="red"
                            @click="
                              (itemdelete = item),
                                (openconfirmcancelusersharefile = true)
                            "
                            >delete</v-icon
                          >
                        </v-flex>
                        <v-flex lg6 xs12 class="mb-n3">
                          <v-dialog
                            persistent
                            v-model="taxfromdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="
                                  pickertax[i] === '' || null
                                    ? setdate(item.number_of_days)
                                    : formatdatetextfield(pickertax[i])
                                "
                                :label="$t('sharefile.setdate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                clearable
                                v-on="on"
                                outlined
                                dense
                                class="pl-2 pr-2"
                                @click="checkpicker = i"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="pickertax[checkpicker]"
                              :min="datenow"
                              @input="taxfromdate = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="taxfromdate = false"
                              >
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>

                        <v-flex lg3 xs6>
                          <v-select
                            :label="$t('sharefile.sethours')"
                            class="pl-2 pr-1"
                            :items="hours"
                            v-model="hourtax[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs6>
                          <v-select
                            class=" pr-2"
                            :label="$t('sharefile.setminute')"
                            :items="minutes"
                            v-model="minutetax[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="opentabs === 0">
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              outlined
              @click="
                closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = []),
                  statusclosereload === false ? $emit('close') : $emit('closesharefile')
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <!-- <v-btn
              :color="color.theme"
              class="white--text"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
              >{{ $t("sharefile.oksharefile") }}</v-btn
            > -->
          </v-card-actions>

          <v-card-actions v-if="opentabs === 1">
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              outlined
              @click="
                closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = []),
                  statusclosereload === false ? $emit('close') : $emit('closesharefile')
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <!-- <v-btn
              :color="color.theme"
              class="white--text"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
              >{{ $t("sharefile.oksharefile") }}</v-btn
            > -->
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- หน้าจอมือถือใหม่ -->
      <v-dialog
        v-else
        v-model="shower"
        persistent
        scrollable
        :width="maxWidthOnMobile"
        content-class="rounded-lg ma-0"
      >
        <v-card class="pa-0">
          <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-1">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">{{
                  $t("sharefile.messageboxheader").substring(0, 20)
                }}</span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="
                    $emit('close'),
                      closevalues(),
                      (setdatetime = []),
                      (minute2 = []),
                      (minutetax = []),
                      (hour2 = []),
                      (hourtax = []),
                      (picker = ''),
                      (minute = ''),
                      (hour = ''),
                      (switch1 = false),
                      (message = []),
                      (messagetax = [])
                  "
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-tabs fixed-tabs v-model="opentabs">
            <v-tab @click="opentabs = 0" class="pt-2">
              <!-- <v-badge
                color="error"
                :content="message.length"
                v-if="message.length > 0"
                >{{ $t("sharefile.personal") }}</v-badge
              >
              <div v-else style="font-size:12px">
                {{ $t("sharefile.personal") }}
              </div> -->
              <v-badge
                color="error"
                :content="listgroup_data.length"
                v-if="listgroup_data.length > 0"
                >{{ $t("sharefile.personal") }}</v-badge
              >
              <div v-else style="font-size:12px">
                {{ $t("sharefile.personal") }}
              </div>
              <!-- <div v-else>{{$t('sharefile.Email')}}</div> -->
            </v-tab>
            <v-tab @click="opentabs = 1" class="pt-2">
              <v-badge
                color="error"
                :content="messagetax.length"
                v-if="messagetax.length > 0"
              >
                {{ $t("sharefile.taxidbusiness") }}
              </v-badge>
              <div v-else style="font-size:12px">
                {{ $t("sharefile.taxidbusiness") }}
              </div>
            </v-tab>
            <v-tab-item class="scroller">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex lg4 md4 xs9 class="text-center mt-6">
                    <v-select
                      outlined
                      dense
                      v-model="typeshare"
                      :items="listtypeshare"
                      item-value="listtypeshare_value"
                      :item-text="
                        $t('default') === 'th'
                          ? 'listtypeshare_type'
                          : 'listtypeshare_type_eng'
                      "
                      :label="$t('sharefile.type')"
                    ></v-select>
                  </v-flex>
                  <v-flex lg7 md7 xs9 class="text-center">
                    <v-text-field
                      outlined
                      dense
                      :disabled="typeshare === ''"
                      v-model="typeshare_data"
                      :label="
                        typeshare === 'thai_email'
                          ? 'One Email'
                          : typeshare === 'username'
                          ? 'Username'
                          : typeshare === 'one_collab_mail'
                          ? 'One Collaboration Email'
                          : $t('default') === 'th'
                          ? 'กรุณาเลือกประเภทผู้ใช้งาน'
                          : 'Please select type'
                      "
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-n5">
                  <v-flex lg11 xs10>
                    <v-switch
                      v-model="switch1"
                      :label="$t('sharefile.settime')"
                    ></v-switch>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg5 xs9 v-if="switch1 === true">
                    <v-dialog
                      persistent
                      v-model="modal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          clearable
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          v-model="picker"
                          :label="$t('sharefile.setdate')"
                          readonly
                          prepend-icon="mdi-calendar"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="picker"
                        :min="datenow"
                        @input="modal = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn dark color="error" @click="modal = false">
                          {{ $t("sharefile.closedatepiker") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                              <v-divider vertical></v-divider>
                            </v-flex> -->
                  <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true ">
                              <v-icon>access_time</v-icon>
                            </v-flex> -->
                  <v-flex lg3 xs5 v-if="switch1 === true">
                    <v-select
                      class="pl-1"
                      :label="$t('sharefile.sethours')"
                      outlined
                      dense
                      :items="hours"
                      v-model="hour"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex lg3 xs4 v-if="switch1 === true">
                    <v-select
                      class="pl-1"
                      :label="$t('sharefile.setminute')"
                      outlined
                      dense
                      :items="minutes"
                      v-model="minute"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg11 xs10 class="text-left">
                    <v-subheader class="pl-1">{{
                      $t("sharefile.setpermissionsharefile")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex lg7 xs6 class="ml-2 pl-4">
                    <v-select
                      v-model="status"
                      :items="values"
                      :label="$t('sharefile.Status')"
                      outlined
                      dense
                      class="elevation-0"
                      return-object
                      :color="color.theme"
                      :item-color="color.theme"
                    >
                      <template v-slot:item="{ item }">{{
                        $t(item.text)
                      }}</template>
                      <template v-slot:selection="{ item }">{{
                        $t(item.text)
                      }}</template>
                    </v-select>
                  </v-flex>
                  <v-flex lg4 xs4 class="text-right mr-5 pr-2">
                    <v-btn
                      :color="color.theme"
                      :dark="color.darkTheme"
                      :disabled="!loaddataprogress"
                      @click="addemail()"
                    >
                      {{ $t("sharefile.share") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                <!-- แชร์กับ -->
                <v-flex xs10 v-if="listgroup_data.length !== 0">
                  <!-- <v-flex lg11 xs10 v-if="message.length !== 0"> -->
                    <v-subheader class="ml-n3 mb-n2">{{
                      $t("sharefile.sharewith")
                    }}</v-subheader>
                  </v-flex>
                <!-- แชร์ผู้ใช้งานทั่วไป แบบใหม่ mobile -->
                    <v-flex sm10 xs10>
                      <v-card :style="displayMobile">
                      <v-list dense two-line v-if="listgroup_data.length !== 0" :style="styleDisplayMobile">
                        <v-list-item v-for="item in listgroup_data" :key="item.title" style="cursor: pointer" :style="displayMobileForListItem">
                          <v-list-item-content>
                            <v-list-item-content v-if="$t('default') === 'th'">{{$t('sharefile.name')}} : {{item.group_mail.name_th}}</v-list-item-content>
                            <v-list-item-content v-else >{{$t('sharefile.name')}} : {{item.group_mail.name_eng}}</v-list-item-content>
                            <v-list-item-subtitle style="line-height:22px;" v-if="item.group_mail.status === 'V'">{{$t('sharefile.Status')}} : {{$t('sharefile.view')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.group_mail.status === 'DO'">{{$t('sharefile.Status')}} : {{$t('sharefile.download')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.group_mail.status === 'U'">{{$t('sharefile.Status')}} : {{$t('sharefile.upload')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.group_mail.status === 'E'">{{$t('sharefile.Status')}} : {{$t('sharefile.edit')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.group_mail.status === 'De'">{{$t('sharefile.Status')}} : {{$t('sharefile.delete')}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="align-self: center;">
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="edit_sharefile(item)"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" style="line-height:22px;">{{$t('sharefile.sharing_not_found')}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      </v-card>
                    </v-flex>
                </v-layout>
                    <template>
                      <v-row justify="center">
                        <v-dialog
                          v-model="sharefile_setting"
                          width="600px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="text-h">{{$t('sharefile.share_detail')}}</span>
                              <v-flex class="d-flex justify-end">
                              <v-btn
                                large
                                color="grey"
                                icon
                                @click="sharefile_setting = false , edit_detail = false, loaddata(), switch_edit = false"
                              >
                                <v-icon>
                                  mdi-close-circle
                                </v-icon>
                              </v-btn>
                              </v-flex>
                            </v-card-title>
                            <v-divider></v-divider>
                            <!-- :prepend-icon="list_sharefile.group_mail.type_shared === '0' ? 'mdi-account' : list_sharefile.group_mail.type_shared === '1' ? 'mdi-email-outline' : 'mdi-email'" -->
                            <v-card-text>
                              <v-layout justify-end class="mt-5">
                                <!-- {{edit_detail}} -->
                              </v-layout>                           

                              <v-flex lg12 md12 sm12 xs12>
                                <div class="d-flex">
                                  <div class="mt-5 align-self-center" outlined tile>
                                    <v-icon>
                                      mdi-file-edit
                                    </v-icon>
                                  </div>
                                  <div class="pa-2">
                                    <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.file_name')+' '+':'}}</p>
                                    <p style="font-size:16px;margin-bottom:0px">{{filedata.file_name}}</p>
                                  </div>
                                </div>
                                <div class="d-flex mb-3">
                                  <div class="mt-5 align-self-center" outlined tile>
                                    <v-icon v-if="sharefile_type_shared === '0'">mdi-account</v-icon>
                                    <v-icon v-else-if="sharefile_type_shared === '1'">mdi-email-outline</v-icon>
                                    <v-icon v-else-if="sharefile_type_shared === '2'">mdi-email</v-icon>
                                  </div>
                                  <div class="pa-2">
                                    <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.name')+' '+':'}}</p>
                                    <p v-if="$t('default') === 'th'" style="font-size:16px;margin-bottom:0px">{{sharefile_name_th}}</p>
                                    <p v-else style="font-size:16px;margin-bottom:0px">{{sharefile_name_eng}}</p>
                                  </div>
                                </div>
                              <v-layout
                                row
                                wrap
                                justify-left
                                pa-1
                                v-for="(item, i) in edit_status_sharefile"
                                :key="i"
                                class="mb-n4"
                              >
                                <!-- <v-flex lg12 xs12 class=" mb-6">
                                  <v-divider></v-divider>
                                </v-flex> -->
                                <!-- สถานะ -->
                                <v-flex lg8 md8 sm8 xs8 class="mb-3">
                                  <v-select
                                    prepend-icon="mdi-map-marker"
                                    class="pl-2"
                                    :label="$t('sharefile.Status')"
                                    hide-details
                                    v-model="statusshare[index_status]"
                                    :items="values"
                                    outlined
                                    dense
                                    return-object
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  >
                                    <template v-slot:item="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                    <template v-slot:selection="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                  </v-select>
                                </v-flex>

                                <!-- วันที่ -->
                                <v-flex lg8 md8 sm8 xs8 class="mb-n3 mt-2">
                                  <v-dialog
                                    persistent
                                    v-model="emailformdate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        clearable
                                        @click:clear="picker2[index_status] = ''"
                                        :label="picker2[index_status] === '' || null ? $t('sharefile.no_date_set') : $t('sharefile.setdate')"
                                        :value="picker2[index_status] === '' || null ? setdate(item.group_mail.number_of_days) : formatdatetextfield(picker2[index_status])"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="pl-2 pr-2"
                                        @click="checkpicker = index_status"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="picker2[checkpicker]"
                                      :min="datenow"
                                      @input="emailformdate = false"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click="emailformdate = false"
                                      >
                                        {{ $t("sharefile.closedatepiker") }}
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- เวลา -->
                                <v-layout>
                                <v-flex lg4 md4 sm4 xs5 class="mt-2" >
                                  <v-select
                                    prepend-icon="mdi-timer"
                                    :label="$t('sharefile.sethours')"
                                    class="pl-2 pr-1"
                                    :items="hours"
                                    v-model="hour2[index_status]"
                                    outlined
                                    dense
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex lg4 md4 sm4 xs5 class="mt-2">
                                  <v-select
                                    class=" pr-2"
                                    :label="$t('sharefile.setminute')"
                                    :items="minutes"
                                    v-model="minute2[index_status]"
                                    dense
                                    outlined
                                  >
                                  </v-select>
                                </v-flex>
                                </v-layout>
                                <!-- ยกเลิกการแชร์ -->
                                  <v-layout style="justify-content: center;" justify-center>
                                  <v-btn
                                    :color="color.theme"
                                    class="white--text mr-2"
                                    :disabled="checkbuttonupdate"
                                    @click="edit_share_detail(),(edit_detail = false)"
                                    :loading="processloading"
                                  >
                                    <v-icon left size="18">
                                    mdi-floppy
                                    </v-icon>
                                    {{$t("sharefile.save")}}
                                  </v-btn>
                                  <v-btn
                                  color="red"
                                  dark
                                  @click="(itemdelete = item.group_mail),(openconfirmcancelusersharefile = true)"
                                  >
                                    <v-icon
                                      left size="18"
                                    >
                                      mdi-share-off
                                    </v-icon>
                                    {{$t("sharefile.cancel_sharing")}}
                                  </v-btn>
                                  </v-layout>
                              </v-layout>
                              </v-flex>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </template>
                <!-- แชร์ผู้ใช้งานทั่วไป แบบเก่า -->
                <!-- <v-layout row wrap justify-center> -->
                  <!-- <v-flex lg11 xs10 v-if="message.length !== 0">
                    <v-subheader class="ml-n3 mb-n2">{{
                      $t("sharefile.sharewith")
                    }}</v-subheader>
                  </v-flex> -->
                  <!-- loading -->
                  <!-- <v-progress-circular
                    v-if="loaddataprogress === false"
                    indeterminate
                    :color="color.theme"
                    size="100"
                  >
                    <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                  </v-progress-circular> -->
                  <!-- Share with -->
                  <!-- <v-flex lg11 xs9> -->
                    <!-- <v-layout
                      row
                      wrap
                      justify-left
                      pa-1
                      v-for="(item, i) in listgroup_data"
                      :key="i"
                      class="mb-n4"
                    > -->
                      <!-- <v-flex lg12 xs12 class=" mb-6">
                        <v-divider></v-divider>
                      </v-flex> -->
                      <!-- <v-flex lg5 xs11 class="mb-2">
                        <v-text-field
                          :label="$t('sharefile.Email')"
                          class="pl-1 pr-2"
                          outlined
                          readonly
                          dense
                          v-model="item.group_mail.name_th"
                          hide-details
                          :color="color.theme"
                        ></v-text-field>
                      </v-flex> -->
                      <!-- เพิ่ม check one collab -->
                      <!-- <v-flex
                        lg1
                        xs1
                        class="pt-2 text-center"
                        v-if="item.group_mail.type_shared === '3'"
                      >
                        <v-btn
                          class="elevation-0"
                          fab
                          x-small
                          v-model="item.group_mail.name_th"
                          @click="detail_group = false"
                          v-if="detail_group == true"
                        >
                          <v-icon>mdi-chevron-double-up</v-icon>
                        </v-btn>
                        <v-btn
                          class="elevation-0"
                          fab
                          x-small
                          v-model="item.group_mail.name_th"
                          @click="detail_group = true"
                          v-else
                        >
                          <v-icon>mdi-chevron-double-down</v-icon>
                        </v-btn>
                      </v-flex> -->
                      <!-- <v-flex lg4 xs7 class="mb-3">
                        <v-select
                          class="pl-1"
                          :label="$t('sharefile.Status')"
                          hide-details
                          v-model="statusshare[i]"
                          :items="values"
                          outlined
                          dense
                          return-object
                          :color="color.theme"
                          :item-color="color.theme"
                        >
                          <template v-slot:item="{ item }">{{
                            $t(item.text)
                          }}</template>
                          <template v-slot:selection="{ item }">{{
                            $t(item.text)
                          }}</template>
                        </v-select>
                      </v-flex> -->
                      <!-- <pre>{{itemdelete}}</pre> -->
                        <!-- {{openconfirmcancelusersharefile}} -->
                      <!-- <v-flex lg1 xs5 class="pt-2 text-center">
                        <v-icon
                          color="red"
                          @click="
                            (itemdelete = item.group_mail),
                              (openconfirmcancelusersharefile = true)
                          "
                          >delete</v-icon
                        >
                      </v-flex> -->
                      <!-- เพิ่ม check one collab -->
                      <!-- <v-layout
                        row
                        wrap
                        justify-left
                        pa-1
                        class="mb-n4 ma-1"
                        v-if="detail_group == true"
                      >
                        <v-list>
                          <v-list-item
                            v-for="(itemm, i) in item.mail_list"
                            :key="i"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="itemm.name_th"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-layout> -->
                      <!-- <v-flex lg6 xs12 class="mb-n3">
                        <v-dialog
                          persistent
                          v-model="emailformdate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              :label="$t('sharefile.setdate')"
                              :value="
                                picker2[i] === '' || null
                                  ? setdate(item.group_mail.number_of_days)
                                  : formatdatetextfield(picker2[i])
                              "
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              class="pl-1 pr-2"
                              @click="checkpicker = i"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="picker2[checkpicker]"
                            :min="datenow"
                            @input="emailformdate = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              dark
                              color="error"
                              @click="emailformdate = false"
                            >
                              {{ $t("sharefile.closedatepiker") }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-flex> -->
                      <!-- <v-flex lg1 class="pl-5 pt-3" v-if="resolutionScreen > 500">
                                  <v-divider vertical></v-divider>
                                </v-flex> -->
                      <!-- <v-flex lg1 xs1 >
                            <v-icon>access_time</v-icon>
                          </v-flex> -->
                      <!-- <v-flex lg3 xs6>
                        <v-select
                          :label="$t('sharefile.sethours')"
                          class="pl-0 pr-0"
                          :items="hours"
                          v-model="hour2[i]"
                          outlined
                          dense
                        >
                        </v-select>
                      </v-flex> -->
                      <!-- <v-flex lg3 xs5 ml-1>
                        <v-select
                          class="pl-0 ml-0"
                          :label="$t('sharefile.setminute')"
                          :items="minutes"
                          v-model="minute2[i]"
                          dense
                          outlined
                        >
                        </v-select>
                      </v-flex> -->
                    <!-- </v-layout> -->
                  <!-- </v-flex> -->
                <!-- </v-layout> -->
              </v-container>
            </v-tab-item>
            <v-tab-item class="scroller">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex lg11 xs9 class="text-center mt-6">
                    <v-text-field
                      v-model="taxid"
                      :disabled="!loaddataprogress"
                      :label="$t('TaxID')"
                      persistent-hint
                      multiple
                      outlined
                      dense
                      :color="color.theme"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center class="mt-n5">
                  <v-flex lg11 xs10>
                    <v-switch
                      v-model="switch1"
                      :label="$t('sharefile.settime')"
                    >
                    </v-switch>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg5 xs9 v-if="switch1 === true">
                    <v-dialog
                      persistent
                      v-model="modal2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          v-model="picker"
                          :label="$t('sharefile.setdate')"
                          readonly
                          prepend-icon="mdi-calendar"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="picker"
                        :min="datenow"
                        @input="modal2 = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn dark color="error" @click="modal2 = false">
                          {{ $t("sharefile.closedatepiker") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                              <v-divider vertical></v-divider>
                            </v-flex> -->
                  <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true">
                              <v-icon>access_time</v-icon>
                            </v-flex> -->
                            <!-- :label="$t('sharefile.sethours')"sethours -->
                  <v-flex lg3 xs5 v-if="switch1 === true">
                    <v-select
                      class="pl-1"
                      :label="$t('sharefile.settime')"
                      outlined
                      dense
                      :items="hours"
                      v-model="hour"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex lg3 xs4 v-if="switch1 === true">
                    <v-select
                      class="pl-1"
                      :label="$t('sharefile.setminute')"
                      outlined
                      dense
                      :items="minutes"
                      v-model="minute"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg11 xs10 class="text-left mb-2">
                    <v-subheader class="pl-1">{{
                      $t("sharefile.setpermissionsharefile")
                    }}</v-subheader>
                  </v-flex>
                  <v-flex lg7 xs6 class="ml-2 pl-4">
                    <v-select
                      v-model="status"
                      :items="values"
                      :label="$t('sharefile.Status')"
                      text
                      dense
                      return-object
                      :color="color.theme"
                      :item-color="color.theme"
                      outlined
                    >
                      <template v-slot:item="{ item }">{{
                        $t(item.text)
                      }}</template>
                      <template v-slot:selection="{ item }">{{
                        $t(item.text)
                      }}</template>
                    </v-select>
                  </v-flex>
                  <v-flex lg4 xs4 class="text-right mr-5 pr-2">
                    <v-btn
                      :color="color.theme"
                      :dark="color.darkTheme"
                      :disabled="!loaddataprogress"
                      @click="checktaxid()"
                    >
                      {{ $t("sharefile.share") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                <!-- แชร์กับ -->
                <v-flex xs10 v-if="messagetax.length !== 0">
                    <v-subheader class="ml-n3 mb-n2">{{
                      $t("sharefile.sharewith")
                    }}</v-subheader>
                </v-flex>
                <!-- แชร์เลขประจำตัวผู้เสียภาษี แบบใหม่ mobile -->
                    <v-flex sm10 xs10>
                      <v-card :style="displayMobile">
                      <v-list dense two-line v-if="messagetax.length !== 0" :style="styleDisplayMobile">
                        <v-list-item v-for="item in messagetax" :key="item.title" style="cursor: pointer" :style="displayMobileForListItem">
                          <v-list-item-content>
                            <v-list-item-content v-if="$t('default') === 'th'">{{$t('sharefile.name')}} : {{item.name_th}}</v-list-item-content>
                            <v-list-item-content v-else >{{$t('sharefile.name')}} : {{item.name_eng}}</v-list-item-content>
                            <v-list-item-subtitle style="line-height:22px;" v-if="item.status === 'V'">{{$t('sharefile.Status')}} : {{$t('sharefile.view')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.status === 'DO'">{{$t('sharefile.Status')}} : {{$t('sharefile.download')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.status === 'U'">{{$t('sharefile.Status')}} : {{$t('sharefile.upload')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.status === 'E'">{{$t('sharefile.Status')}} : {{$t('sharefile.edit')}}</v-list-item-subtitle>
                            <v-list-item-subtitle style="line-height:22px;" v-else-if="item.status === 'De' || item.status === 'DE'">{{$t('sharefile.Status')}} : {{$t('sharefile.delete')}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="align-self: center;">
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="edit_sharefile_tax(item)"
                            >
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-list v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" style="line-height:22px;">{{$t('sharefile.sharing_not_found')}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      </v-card>
                    </v-flex>
                  
                </v-layout>
                    <template>
                          <v-row justify="center">
                            <v-dialog
                              v-model="sharefile_setting_tax"
                              width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="text-h">{{$t('sharefile.share_detail')}}</span>
                                  <v-flex class="d-flex justify-end">
                                  <v-btn
                                    large
                                    color="grey"
                                    icon
                                    @click="sharefile_setting_tax = false, edit_detail = false, loaddatataxid()"
                                  >
                                    <v-icon>
                                      mdi-close-circle
                                    </v-icon>
                                  </v-btn>
                                  </v-flex>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <v-layout justify-end class="mt-5">
                                  </v-layout>
                                  <v-flex lg12 md12 sm12 xs12>
                                    <div class="d-flex">
                                      <div class="mt-5 align-self-center" outlined tile>
                                        <v-icon>
                                          mdi-file-edit
                                        </v-icon>
                                      </div>
                                      <div class="pa-2">
                                        <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.file_name')+' '+':'}}</p>
                                        <p style="font-size:16px;margin-bottom:0px">{{filedata.file_name}}</p>
                                      </div>
                                    </div>
                                    <div class="d-flex mb-3">
                                      <div class="mt-5 align-self-center" outlined tile>
                                        <v-icon>mdi-domain</v-icon>
                                      </div>
                                      <div class="pa-2">
                                        <p style="font-size:12px;margin-bottom:0px">{{$t('sharefile.name')+' '+':'}}</p>
                                        <p v-if="$t('default') === 'th'" style="font-size:16px;margin-bottom:0px">{{sharefile_name_th_tax}}</p>
                                        <p v-else style="font-size:16px;margin-bottom:0px">{{sharefile_name_eng_tax}}</p>
                                      </div>
                                    </div>
                                    
                                  <!-- {{list_sharefile_tax}} -->
                                  <v-layout
                                    row
                                    wrap
                                    justify-left
                                    pa-1
                                    v-for="(item, i) in edit_status_sharefile_tax"
                                    :key="i"
                                    class="mb-n4"
                                  >
                                  <!-- {{statussharetax[index_status_tax]}} -->
                                  <!-- {{list_sharefile_tax}} -->
                                    <!-- สถานะ -->
                                    <v-flex lg8 md8 sm8 xs8 class="mb-3">
                                      <v-select
                                        prepend-icon="mdi-map-marker"
                                        class="pl-2"
                                        :label="$t('sharefile.Status')"
                                        hide-details
                                        v-model="statussharetax[index_status_tax]"
                                        :items="values"
                                        outlined
                                        dense
                                        return-object
                                        :color="color.theme"
                                        :item-color="color.theme"
                                      >
                                        <template v-slot:item="{ item }">{{
                                          $t(item.text)
                                        }}</template>
                                        <template v-slot:selection="{ item }">{{
                                          $t(item.text)
                                        }}</template>
                                      </v-select>
                                    </v-flex>
                                    <!-- วันที่ -->
                                    <v-flex lg8 md8 sm8 xs8 class="mb-n3 mt-2">
                                      <v-dialog
                                        persistent
                                        v-model="taxfromdate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            clearable
                                            @click:clear="picker2[index_status_tax] = ''"
                                            :label="pickertax[index_status_tax] === '' || null ? $t('sharefile.no_date_set') : $t('sharefile.setdate')"
                                            :value="pickertax[index_status_tax] === '' || null ? setdate(item.number_of_days) : formatdatetextfield(pickertax[index_status_tax])"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            class="pl-2 pr-2"
                                            @click="checkpicker = index_status_tax"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="pickertax[checkpicker]"
                                          :min="datenow"
                                          @input="taxfromdate = false"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click="taxfromdate = false"
                                          >
                                            {{ $t("sharefile.closedatepiker") }}
                                          </v-btn>
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <!-- เวลา -->
                                    <v-layout>
                                    <v-flex lg4 md4 sm4 xs5 class="mt-2">
                                      <!-- {{hourtax[index_status_tax]}} -->
                                      <v-select
                                        prepend-icon="mdi-timer"
                                        :label="$t('sharefile.sethours')"
                                        class="pl-2 pr-1"
                                        :items="hours"
                                        v-model="hourtax[index_status_tax]"
                                        outlined
                                        dense
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex lg4 md4 sm4 xs5 class="mt-2">
                                      <!-- {{minutetax[index_status_tax]}} -->
                                      <v-select
                                        class=" pr-2"
                                        :label="$t('sharefile.setminute')"
                                        :items="minutes"
                                        v-model="minutetax[index_status_tax]"
                                        dense
                                        outlined
                                      >
                                      </v-select>
                                    </v-flex>
                                    </v-layout>
                                    <!-- ยกเลิกการแชร์ -->
                                    <v-flex lg12 md12 sm12 xs12 class="pt-2 ml-8 text-center">
                                      <!-- {{openconfirmcancelusersharefile}} -->
                                      <v-layout style="justify-content: center;" justify-center>
                                      <v-btn
                                        :color="color.theme"
                                        class="white--text mr-2"
                                        :disabled="checkbuttonupdate"
                                        @click="edit_share_detail(),edit_detail = false"
                                        :loading="processloading"
                                      >
                                        <v-icon left size="18">
                                        mdi-floppy
                                        </v-icon>
                                        {{$t("sharefile.save")}}
                                      </v-btn>
                                      <v-btn
                                      color="red"
                                      dark
                                      @click="(itemdelete = item),(openconfirmcancelusersharefile = true)"
                                      >
                                        <v-icon
                                          left size="18"
                                        >
                                          mdi-share-off
                                        </v-icon>
                                        {{$t("sharefile.cancel_sharing")}}
                                      </v-btn>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                  </v-flex>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-row>
                        </template>
                <!-- แชร์เลขประจำตัวผู้เสียภาษี แบบเก่า -->
                <!-- <v-layout row wrap justify-center> -->
                  <!-- <v-flex lg11 xs10 v-if="messagetax.length !== 0">
                    <v-subheader class="ml-n3 mb-n2">{{
                      $t("sharefile.sharewith")
                    }}</v-subheader>
                  </v-flex> -->
                  <!-- loadingtax -->
                  <!-- <v-progress-circular
                    v-if="loaddataprogress === false"
                    indeterminate
                    :color="color.theme"
                    size="100"
                  >
                    <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                  </v-progress-circular> -->
                  <!-- Share with tax-->
                  <!-- <v-flex lg11 xs9> -->
                    <!-- <v-layout
                      row
                      wrap
                      justify-center
                      pa-1
                      v-for="(item, i) in messagetax"
                      :key="i"
                      class="mb-n4"
                    > -->
                      <!-- <v-flex lg12 xs12 class=" mb-6">
                        <v-divider></v-divider>
                      </v-flex> -->
                      <!-- <v-flex lg7 xs12 class="mb-3">
                        <v-text-field
                          class="pl-2 pr-2"
                          v-if="$t('default') === 'th'"
                          v-model="item.name_th"
                          outlined
                          readonly
                          dense
                          hide-details
                          :color="color.theme"
                          :label="$t('sharefile.business')"
                        ></v-text-field>
                        <v-text-field
                          class="pl-2 pr-2"
                          v-else
                          v-model="item.name_eng"
                          outlined
                          readonly
                          dense
                          hide-details
                          :color="color.theme"
                          :label="$t('sharefile.business')"
                        ></v-text-field>
                      </v-flex> -->
                      <!-- <v-flex lg4 xs7 class="mb-3">
                        <v-select
                          class="pl-2"
                          hide-details
                          :items="values"
                          :label="$t('sharefile.Status')"
                          v-model="statussharetax[i]"
                          outlined
                          dense
                          return-object
                          :color="color.theme"
                          :item-color="color.theme"
                        >
                          <template v-slot:item="{ item }">{{
                            $t(item.text)
                          }}</template>
                          <template v-slot:selection="{ item }">{{
                            $t(item.text)
                          }}</template>
                        </v-select>
                      </v-flex> -->
                      <!-- <v-flex lg1 xs5 class="pt-2 text-center">
                        <v-icon
                          color="red"
                          @click="
                            (itemdelete = item),
                              (openconfirmcancelusersharefile = true)
                          "
                          >delete</v-icon
                        >
                      </v-flex> -->
                      <!-- <v-flex lg6 xs12 class="mb-n3">
                        <v-dialog
                          persistent
                          v-model="taxfromdate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="
                                pickertax[i] === '' || null
                                  ? setdate(item.number_of_days)
                                  : formatdatetextfield(pickertax[i])
                              "
                              :label="$t('sharefile.setdate')"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              clearable
                              v-on="on"
                              outlined
                              dense
                              class="pl-2 pr-2"
                              @click="checkpicker = i"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="pickertax[checkpicker]"
                            :min="datenow"
                            @input="taxfromdate = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              dark
                              color="error"
                              @click="taxfromdate = false"
                            >
                              {{ $t("sharefile.closedatepiker") }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-flex> -->
                      <!-- <v-flex lg1 class="pl-6 pt-3">
                                  <v-divider vertical></v-divider>
                                </v-flex> -->
                      <!-- <v-flex lg1 xs1 class="pt-4 pl-2" >
                            <v-icon>access_time</v-icon>
                          </v-flex> -->
                      <!-- <v-flex lg3 xs6>
                        <v-select
                          :label="$t('sharefile.sethours')"
                          class="pl-0 pr-0"
                          :items="hours"
                          v-model="hourtax[i]"
                          outlined
                          dense
                        >
                        </v-select>
                      </v-flex> -->
                      <!-- <v-flex lg3 xs5 ml-1>
                        <v-select
                          class=" pr-0"
                          :label="$t('sharefile.setminute')"
                          :items="minutes"
                          v-model="minutetax[i]"
                          outlined
                          dense
                        >
                        </v-select>
                      </v-flex> -->
                    <!-- </v-layout> -->
                  <!-- </v-flex> -->
                <!-- </v-layout> -->
              </v-container>
            </v-tab-item>
          </v-tabs>
          <div v-if="opentabs === 0" class="text-center my-4">
            <v-btn
              class="mr-2"
              height="40px"
              width="30%"
              color="red"
              outlined
              @click="
                closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = []),
                  statusclosereload === false ? $emit('close') : $emit('closesharefile')
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <!-- <v-btn
              class="elevation-0 white--text"
              :style="btnAction"
              height="40px"
              width="40%"
              :color="color.theme"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
            >
              {{ $t("sharefile.oksharefile") }}
            </v-btn> -->
          </div>
          <div v-if="opentabs === 1" class="text-center my-4">
            <v-btn
              class="mr-2"
              height="40px"
              width="30%"
              color="red"
              outlined
              @click="
                closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = []),
                  statusclosereload === false ? $emit('close') : $emit('closesharefile')
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <!-- <v-btn
              class="elevation-0 white--text"
              :style="btnAction"
              height="40px"
              width="40%"
              :color="color.theme"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
            >
              {{ $t("sharefile.oksharefile") }}
            </v-btn> -->
          </div>
        </v-card>
      </v-dialog>
      <!-- หน้าจอมือถือเก่า -->
      <!-- <v-dialog v-model="shower" max-width="500px" persistent scrollable v-else> -->
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template>-->
      <!-- <v-card scrollable persistent>
          <v-card-title>
            <v-layout>
              <v-flex lg11 xs10 style="font-size:15px">
                {{ $t("sharefile.messageboxheader").substring(0, 20) }} -->
      <!-- {{ $t("sharefile.messageboxheader").substring(0, 20) + "..." }} -->
      <!-- </v-flex>
              <v-flex lg1 xs2 class="text-right"
                ><v-icon :color="color.theme" dense dark size="28"
                  >group_add</v-icon
                ></v-flex
              >
            </v-layout> -->
      <!-- {{ $t("sharefile.messageboxheader") }}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">group_add</v-icon> -->
      <!-- </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="height:420px" class="pt-4">
            <v-tabs v-model="opentabs" mobile-breakpoint class="elevation-2">
              <v-tab @click="opentabs = 0" class="pt-2">
                <v-badge
                  color="error"
                  :content="message.length"
                  v-if="message.length > 0"
                  >{{ $t("sharefile.personal") }}</v-badge
                >
                <div v-else style="font-size:12px">
                  {{ $t("sharefile.personal") }}
                </div> -->
      <!-- <div v-else>{{$t('sharefile.Email')}}</div> -->
      <!-- </v-tab> -->
      <!-- <v-tab @click="(opentabs = 1)">
                      <v-badge color="error" :content="messagetax.length" v-if="messagetax.length > 0"
                        >เลขประจำตัวผู้เสียภาษี (องค์กร)</v-badge
                      >
                      <v-div v-else>เลขประจำตัวผู้เสียภาษี (องค์กร)</v-div>
                    </v-tab> -->
      <!-- <v-tab @click="opentabs = 1" class="pt-2">
                <v-badge
                  color="error"
                  :content="messagetax.length"
                  v-if="messagetax.length > 0"
                >
                  {{ $t("sharefile.taxidbusiness") }}
                </v-badge>

                <div v-else style="font-size:12px">
                  {{ $t("sharefile.taxidbusiness") }}
                </div>
              </v-tab>
              <v-tab-item> -->
      <!-- {{opentabs}} -->
      <!-- <v-container fluid>
                  <v-layout row wrap justify-center>
                    <v-flex lg4 md4 xs9 class="text-center mt-6">
                      <v-select
                        outlined
                        dense
                        v-model="typeshare"
                        :items="listtypeshare"
                        item-value="listtypeshare_value"
                        :item-text="
                          $t('default') === 'th'
                            ? 'listtypeshare_type'
                            : 'listtypeshare_type_eng'
                        "
                        :label="$t('sharefile.type')"
                      ></v-select>
                    </v-flex>
                    <v-flex lg7 md7 xs9 class="text-center">
                      <v-text-field
                        outlined
                        dense
                        :disabled="typeshare === ''"
                        v-model="typeshare_data"
                        :label="
                          typeshare === 'thai_email'
                            ? 'Email'
                            : typeshare === 'username'
                            ? 'Username'
                            : typeshare === 'one_collab_mail'
                            ? 'One Collaboration Email'
                            : $t('default') === 'th'
                            ? 'กรุณาเลือกประเภทผู้ใช้งาน'
                            : 'Please select type'
                        "
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center class="mt-n5">
                    <v-flex lg11 xs10>
                      <v-switch
                        v-model="switch1"
                        :label="$t('sharefile.settime')"
                      ></v-switch>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap justify-center>
                    <v-flex lg5 xs9 v-if="switch1 === true">
                      <v-dialog
                        persistent
                        v-model="modal"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            v-model="picker"
                            :label="$t('sharefile.setdate')"
                            readonly
                            prepend-icon="mdi-calendar"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          :min="datenow"
                          @input="modal = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="modal = false">
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex> -->
      <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                                <v-divider vertical></v-divider>
                              </v-flex> -->
      <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true ">
                                <v-icon>access_time</v-icon>
                              </v-flex> -->
      <!-- <v-flex lg3 xs5 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.sethours')"
                        outlined
                        dense
                        :items="hours"
                        v-model="hour"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex lg3 xs4 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.setminute')"
                        outlined
                        dense
                        :items="minutes"
                        v-model="minute"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs10 class="text-left">
                      <v-subheader class="pl-1">{{
                        $t("sharefile.setpermissionsharefile")
                      }}</v-subheader>
                    </v-flex>
                    <v-flex lg7 xs6 class="ml-2 pl-4">
                      <v-select
                        v-model="status"
                        :items="values"
                        :label="$t('sharefile.Status')"
                        outlined
                        dense
                        class="elevation-0"
                        return-object
                        :color="color.theme"
                        :item-color="color.theme"
                      >
                        <template v-slot:item="{ item }">{{
                          $t(item.text)
                        }}</template>
                        <template v-slot:selection="{ item }">{{
                          $t(item.text)
                        }}</template>
                      </v-select>
                    </v-flex>
                    <v-flex lg4 xs4 class="text-right mr-5 pr-2">
                      <v-btn
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :disabled="!loaddataprogress"
                        @click="addemail()"
                      >
                        {{ $t("sharefile.share") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs10 v-if="message.length !== 0">
                      <v-subheader class="ml-n3 mb-n2">{{
                        $t("sharefile.sharewith")
                      }}</v-subheader>
                    </v-flex> -->
      <!-- loading -->
      <!-- <v-progress-circular
                      v-if="loaddataprogress === false"
                      indeterminate
                      :color="color.theme"
                      size="100"
                    >
                      <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                    </v-progress-circular> -->
      <!-- Share with -->
      <!-- <v-flex lg11 xs9>
                      <v-layout
                        row
                        wrap
                        justify-left
                        pa-1
                        v-for="(item, i) in listgroup_data"
                        :key="i"
                        class="mb-n4"
                      >
                        <v-flex lg12 xs12 class=" mb-6">
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex lg5 xs11 class="mb-2">
                          <v-text-field
                            :label="$t('sharefile.Email')"
                            class="pl-1 pr-2"
                            outlined
                            readonly
                            dense
                            v-model="item.group_mail.name_th"
                            hide-details
                            :color="color.theme"
                          ></v-text-field>
                        </v-flex> -->
      <!-- เพิ่ม check one collab -->
      <!-- <v-flex
                          lg1
                          xs1
                          class="pt-2 text-center"
                          v-if="item.group_mail.type_shared === '3'"
                        >
                          <v-btn
                            class="elevation-0"
                            fab
                            x-small
                            v-model="item.group_mail.name_th"
                            @click="detail_group = false"
                            v-if="detail_group == true"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="elevation-0"
                            fab
                            x-small
                            v-model="item.group_mail.name_th"
                            @click="detail_group = true"
                            v-else
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex lg4 xs7 class="mb-3">
                          <v-select
                            class="pl-1"
                            :label="$t('sharefile.Status')"
                            hide-details
                            v-model="statusshare[i]"
                            :items="values"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex> -->
      <!-- <pre>{{itemdelete}}</pre> -->
      <!-- <v-flex lg1 xs5 class="pt-2 text-center"> -->
      <!-- {{openconfirmcancelusersharefile}} -->

      <!-- <v-icon
                            color="red"
                            @click="
                              (itemdelete = item.group_mail),
                                (openconfirmcancelusersharefile = true)
                            "
                            >delete</v-icon
                          >
                        </v-flex> -->

      <!-- เพิ่ม check one collab -->
      <!-- <v-layout
                          row
                          wrap
                          justify-left
                          pa-1
                          class="mb-n4 ma-1"
                          v-if="detail_group == true"
                        >
                          <v-list>
                            <v-list-item
                              v-for="(itemm, i) in item.mail_list"
                              :key="i"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="itemm.name_th"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-layout>
                        <v-flex lg6 xs12 class="mb-n3">
                          <v-dialog
                            persistent
                            v-model="emailformdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                clearable
                                :label="$t('sharefile.setdate')"
                                :value="
                                  picker2[i] === '' || null
                                    ? setdate(item.group_mail.number_of_days)
                                    : formatdatetextfield(picker2[i])
                                "
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                class="pl-1 pr-2"
                                @click="checkpicker = i"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="picker2[checkpicker]"
                              :min="datenow"
                              @input="emailformdate = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="emailformdate = false"
                              >
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex> -->
      <!-- <v-flex lg1 class="pl-5 pt-3" v-if="resolutionScreen > 500">
                                    <v-divider vertical></v-divider>
                                  </v-flex> -->
      <!-- <v-flex lg1 xs1 >
                              <v-icon>access_time</v-icon>
                            </v-flex> -->
      <!-- <v-flex lg3 xs6>
                          <v-select
                            :label="$t('sharefile.sethours')"
                            class="pl-0 pr-0"
                            :items="hours"
                            v-model="hour2[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs5 ml-1>
                          <v-select
                            class="pl-0 ml-0"
                            :label="$t('sharefile.setminute')"
                            :items="minutes"
                            v-model="minute2[i]"
                            dense
                            outlined
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-tab-item>
              <v-tab-item> -->
      <!-- {{opentabs}} -->
      <!-- <v-container fluid>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs9 class="text-center mt-6">
                      <v-text-field
                        v-model="taxid"
                        :disabled="!loaddataprogress"
                        :label="$t('TaxID')"
                        persistent-hint
                        multiple
                        outlined
                        dense
                        :color="color.theme"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center class="mt-n5">
                    <v-flex lg11 xs10>
                      <v-switch
                        v-model="switch1"
                        :label="$t('sharefile.settime')"
                      >
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg5 xs9 v-if="switch1 === true">
                      <v-dialog
                        persistent
                        v-model="modal2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            v-model="picker"
                            :label="$t('sharefile.setdate')"
                            readonly
                            prepend-icon="mdi-calendar"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          :min="datenow"
                          @input="modal2 = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="modal2 = false">
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex> -->
      <!-- <v-flex lg1 class="pl-5" v-if="switch1 === true && resolutionScreen > 500">
                                <v-divider vertical></v-divider>
                              </v-flex> -->
      <!-- <v-flex lg1 xs1 class="pt-3 pl-2" v-if="switch1 === true">
                                <v-icon>access_time</v-icon>
                              </v-flex> -->
      <!-- <v-flex lg3 xs5 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.sethours')"
                        outlined
                        dense
                        :items="hours"
                        v-model="hour"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex lg3 xs4 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.setminute')"
                        outlined
                        dense
                        :items="minutes"
                        v-model="minute"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs10 class="text-left mb-2">
                      <v-subheader class="pl-1">{{
                        $t("sharefile.setpermissionsharefile")
                      }}</v-subheader>
                    </v-flex>
                    <v-flex lg7 xs6 class="ml-2 pl-4">
                      <v-select
                        v-model="status"
                        :items="values"
                        :label="$t('sharefile.Status')"
                        text
                        dense
                        return-object
                        :color="color.theme"
                        :item-color="color.theme"
                        outlined
                      >
                        <template v-slot:item="{ item }">{{
                          $t(item.text)
                        }}</template>
                        <template v-slot:selection="{ item }">{{
                          $t(item.text)
                        }}</template>
                      </v-select>
                    </v-flex>
                    <v-flex lg4 xs4 class="text-right mr-5 pr-2">
                      <v-btn
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :disabled="!loaddataprogress"
                        @click="checktaxid()"
                      >
                        {{ $t("sharefile.share") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg11 xs10 v-if="messagetax.length !== 0">
                      <v-subheader class="ml-n3 mb-n2">{{
                        $t("sharefile.sharewith")
                      }}</v-subheader>
                    </v-flex> -->
      <!-- loadingtax -->
      <!-- <v-progress-circular
                      v-if="loaddataprogress === false"
                      indeterminate
                      :color="color.theme"
                      size="100"
                    >
                      <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                    </v-progress-circular> -->
      <!-- Share with tax-->
      <!-- <v-flex lg11 xs9>
                      <v-layout
                        row
                        wrap
                        justify-center
                        pa-1
                        v-for="(item, i) in messagetax"
                        :key="i"
                        class="mb-n4"
                      >
                        <v-flex lg12 xs12 class=" mb-6">
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex lg7 xs12 class="mb-3">
                          <v-text-field
                            class="pl-2 pr-2"
                            v-if="$t('default') === 'th'"
                            v-model="item.name_th"
                            outlined
                            readonly
                            dense
                            hide-details
                            :color="color.theme"
                            :label="$t('sharefile.business')"
                          ></v-text-field>
                          <v-text-field
                            class="pl-2 pr-2"
                            v-else
                            v-model="item.name_eng"
                            outlined
                            readonly
                            dense
                            hide-details
                            :color="color.theme"
                            :label="$t('sharefile.business')"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg4 xs7 class="mb-3">
                          <v-select
                            class="pl-2"
                            hide-details
                            :items="values"
                            :label="$t('sharefile.Status')"
                            v-model="statussharetax[i]"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex>
                        <v-flex lg1 xs5 class="pt-2 text-center">
                          <v-icon
                            color="red"
                            @click="
                              (itemdelete = item),
                                (openconfirmcancelusersharefile = true)
                            "
                            >delete</v-icon
                          >
                        </v-flex>
                        <v-flex lg6 xs12 class="mb-n3">
                          <v-dialog
                            persistent
                            v-model="taxfromdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="
                                  pickertax[i] === '' || null
                                    ? setdate(item.number_of_days)
                                    : formatdatetextfield(pickertax[i])
                                "
                                :label="$t('sharefile.setdate')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                clearable
                                v-on="on"
                                outlined
                                dense
                                class="pl-2 pr-2"
                                @click="checkpicker = i"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="pickertax[checkpicker]"
                              :min="datenow"
                              @input="taxfromdate = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="taxfromdate = false"
                              >
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex> -->
      <!-- <v-flex lg1 class="pl-6 pt-3">
                                    <v-divider vertical></v-divider>
                                  </v-flex> -->
      <!-- <v-flex lg1 xs1 class="pt-4 pl-2" >
                              <v-icon>access_time</v-icon>
                            </v-flex> -->
      <!-- <v-flex lg3 xs6>
                          <v-select
                            :label="$t('sharefile.sethours')"
                            class="pl-0 pr-0"
                            :items="hours"
                            v-model="hourtax[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg3 xs5 ml-1>
                          <v-select
                            class=" pr-0"
                            :label="$t('sharefile.setminute')"
                            :items="minutes"
                            v-model="minutetax[i]"
                            outlined
                            dense
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-if="opentabs === 0" style="padding:12px">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="red"
              outlined
              @click="
                $emit('closesharefile'),
                  closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = [])
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <v-btn
              :color="color.theme"
              class="white--text"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
              >{{ $t("sharefile.oksharefile") }}</v-btn
            >
          </v-card-actions>

          <v-card-actions v-if="opentabs === 1" style="padding:12px">
            <v-spacer></v-spacer>
            <v-btn
              small
              color="red"
              outlined
              @click="
                $emit('closesharefile'),
                  closevalues(),
                  (setdatetime = []),
                  (minute2 = []),
                  (minutetax = []),
                  (hour2 = []),
                  (hourtax = []),
                  (picker = ''),
                  (minute = ''),
                  (hour = ''),
                  (switch1 = false),
                  (message = []),
                  (messagetax = [])
              "
              >{{ $t("uploadfolder.buttonclose") }}</v-btn
            >
            <v-btn
              :color="color.theme"
              class="white--text"
              :disabled="checkbuttonupdate"
              @click="editapi()"
              :loading="processloading"
              >{{ $t("sharefile.oksharefile") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </v-row>
    <!-- <editsharefile
      :editdialog="editsharefile"
      @closesharefile="editsharefile= false"
      :filedata="filedata"
    ></editsharefile>-->
    <confirmcancelusersharefile
      :show="openconfirmcancelusersharefile"
      :filedata="itemdelete"
      :typedata="filedata.file_type === 'folder' ? 'folder' : 'file'"
      @confirmemail="
        deletemessage(itemdelete), (openconfirmcancelusersharefile = false),(sharefile_setting = false)
      "
      @confirmtax="
        deletemessagetax(itemdelete), (openconfirmcancelusersharefile = false),(sharefile_setting_tax = false)
      "
      @closesharefile="openconfirmcancelusersharefile = false"
    ></confirmcancelusersharefile>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dialogLoginssoVue from "./dialog-loginsso.vue";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import format from "date-fns/format";
const editsharefile = () => import("../optional/dialog-editsharefile");
const confirmcancelusersharefile = () =>
  import("../optional/dialog-confirmcancelusersharefile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "filedata"],
  components: { editsharefile, confirmcancelusersharefile },
  data: function() {
    return {
      list_sharefile:[],
      list_sharefile_tax:[],
      sharefile_setting:false,
      sharefile_setting_tax:false,
      listgroup_data_normal: [],
      listgroup_data: [],
      list_id: [],
      detail_group: false,
      itemdelete: [],
      openconfirmcancelusersharefile: false,
      datenow: "",
      emailformdate: false,
      taxfromdate: false,
      fakedate: "2000-01-01",
      processloading: false,
      loaddataprogress: false,
      sethour: "",
      setminute: "",
      switch1: false,
      checkpicker: 0,
      menu2: [],
      menutex: [],
      typeshare: "thai_email",
      typeshare_data: "",
      dateymd: "",
      datehour: "",
      dateminute: "",
      datetimecheck: "",
      datetimecheck2: "",
      minute: "",
      hour: "",
      minute2: [],
      minutetax: [],
      hour2: [],
      hourtax: [],
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      time: "",
      modal2: false,
      modal: false,
      editdate: "",
      edithour: "",
      editmin: "",
      picker: "",
      picker2: [],
      pickertax: [],
      taxid: "",
      item: "",
      setdates: "",
      opentabs: 0,
      setdatetime: [],
      message: [],
      group_mail_list: [],
      messagetax: [],
      statusshare: [],
      statussharetax: [],
      statusshare_: [],
      status: { name: "view", key: "V", text: "sharefile.view" },
      showdialog: false,
      email: "",
      emailadd: [],
      dataset: [],
      statusclosereload: false,
      // defaultSelected:  { name: "view", key: "V", text: "sharefile.view" },
      values: [
        { name: "view", key: "V", text: "sharefile.view" },
        { name: "download", key: "DO", text: "sharefile.download" },
        { name: "upload", key: "U", text: "sharefile.upload" },
        { name: "edit", key: "E", text: "sharefile.edit" },
        { name: "delete", key:"De", text:"sharefile.delete" },
        { name: "delete", key:"DE", text:"sharefile.delete" }
      ],
      editsharefile: false,
      listtypeshare: [
        {
          listtypeshare_type: "วัน อีเมล (One Email)",
          listtypeshare_type_eng: "One Email",
          listtypeshare_value: "thai_email",
        },
        {
          listtypeshare_type: "ผู้ใช้งาน (Username)",
          listtypeshare_type_eng: "Username",
          listtypeshare_value: "username",
        },
        {
          listtypeshare_type: " One Collaboration Email",
          listtypeshare_type_eng: " One Collaboration Email",
          listtypeshare_value: "one_collab_mail",
        },
      ],
      header: [
        {
          text: "#",
          align: "center",
          value: "type",
          width: "10%",
          sortable: false,
        },
        {
          text: "sharefile.name",
          align: "center",
          value: "name",
          width: "60%",
          sortable: false,
        }, 
        {
          text: "sharefile.Status",
          align: "center",
          value: "status",
          width: "20%",
          sortable: false,
        },
        // {
        //   text: "sharefile.edit",
        //   align: "",
        //   value: "status",
        //   width: "10%",
        //   sortable: false,
        // },   
      ],
      size: 20,
      page: 1,
      sharefile_name_th:"",
      sharefile_name_eng:"",
      sharefile_status:"",
      sharefile_type_shared:"",
      sharefile_number_of_days:"",
      edit_status_sharefile:[],
      index_status: -1,
      edit_open:false,
      edit_detail: false,
      edit_status:"",
      edit_key:"",
      index_status_tax: -1,
      edit_status_sharefile_tax:[],
      sharefile_name_th_tax:"",
      sharefile_name_eng_tax:"",
      sharefile_status_tax:"",
      sharefile_number_of_days_tax:"",
      switch_edit:false
      
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 22px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.datenow = format(new Date(), "yyyy-MM-dd");
          // this.opentabs = 0
          this.loaddata();
          this.loaddatataxid();
          console.log("testloadshare");
          // if (this.opentabs === 0) {
          //   this.loaddata();
          // } else {
          //   this.loaddatataxid();
          // }
          this.$emit("closeDrag");
        } else {
          this.statusclosereload = false;
        }
        return this.show;
      },
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    checkbuttonupdate() {
      if (this.opentabs === 0) {
        console.log("this.statusshare", this.statusshare);
        if (this.statusshare.length == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        console.log("this.statussharetax", this.statussharetax);
        if (this.statussharetax.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    check_status(sharefile_status){
      console.log("sharefile_status",sharefile_status);
      if(sharefile_status === 'V'){
        $t('sharefile.view')
      }else if(sharefile_status === 'DO'){
        $t('sharefile.download')
      }else if (sharefile_status === 'U'){
        $t('sharefile.upload')
      }else if (sharefile_status === 'E'){
        $t('sharefile.edit')
      }else if (sharefile_status === 'De'){
        $t('sharefile.delete')
      } return
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null || _datetime === "00000000000000") {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute;
      }
    },
    setting_sharefile(){
      console.log("setting_sharefile");
      this.edit_open = true;

    },
    edit_sharefile(item){
      this.sharefile_setting = true
      this.list_sharefile = item;
      this.edit_status_sharefile = [item];
      console.log("list_sharefile",this.list_sharefile);
      console.log("edit_status_sharefile",this.edit_status_sharefile);
      this.sharefile_name_th = this.list_sharefile.group_mail.name_th;
      this.sharefile_name_eng = this.list_sharefile.group_mail.name_eng;
      this.sharefile_status = this.list_sharefile.group_mail.status;
      this.sharefile_type_shared = this.list_sharefile.group_mail.type_shared
      this.sharefile_number_of_days = this.list_sharefile.group_mail.number_of_days
      console.log("sharefile_name_th", this.sharefile_name_th);
      console.log("sharefile_status", this.sharefile_status);
      console.log("sharefile_type_shared", this.sharefile_type_shared);
      console.log("sharefile_number_of_days", this.sharefile_number_of_days);
      // หาค่าตำแหน่ง array ของ list_sharefile(ค่าใน array ที่เราคลิ๊กเลือกต้องการให้แสดง) โดยใช้ค่าที่เทียบหาตำแหน่ง(account_id_reciever) ตรงกับ listgroup_data(array ทั้งหมดทุกก้อน) เพื่อหาตำแหน่งที่อยู่ของ array
      this.index_status = this.listgroup_data.findIndex((el) => el.group_mail.account_id_reciever === this.list_sharefile.group_mail.account_id_reciever);
      this.edit_status = this.statusshare[this.index_status].text;
      this.edit_key = this.statusshare[this.index_status].key;
      console.log("edit_status",this.edit_status);
      console.log("emailformdate",this.emailformdate);
    },
    edit_sharefile_tax(item){
      this.sharefile_setting_tax = true
      this.list_sharefile_tax = item;
      this.edit_status_sharefile_tax = [item];
      console.log("list_sharefile_tax",this.list_sharefile_tax);
      console.log("edit_status_sharefile_tax",this.edit_status_sharefile_tax);
      this.sharefile_name_th_tax = this.list_sharefile_tax.name_th
      this.sharefile_name_eng_tax = this.list_sharefile_tax.name_eng
      this.sharefile_status_tax = this.list_sharefile_tax.status
      this.sharefile_number_of_days_tax = this.list_sharefile_tax.number_of_days
      this.index_status_tax = this.messagetax.findIndex((el) => el.account_id_reciever === this.list_sharefile_tax.account_id_reciever);
      console.log("index_status_tax",this.index_status_tax);
      console.log("sharefile_name_th_tax",this.sharefile_name_th_tax);
      console.log("sharefile_status_tax",this.sharefile_status_tax);
      console.log("sharefile_number_of_days",this.sharefile_number_of_days_tax);
    },
    settimeminute(_date) {
      let dateminute = _date.split("")[10] + _date.split("")[11];
      return dateminute;
    },
    settimehour(_date) {
      let datehour = _date.split("")[8] + _date.split("")[9];
      return datehour;
    },
    setdate(_date) {
      let dateyear =
        _date.split("")[0] +
        _date.split("")[1] +
        _date.split("")[2] +
        _date.split("")[3];

      let datemount = _date.split("")[4] + _date.split("")[5];

      let dateday = _date.split("")[6] + _date.split("")[7];
      // this.setdatetime.push(dateday + "-" +datemount+ "-" + dateyear)
      console.log(dateday + "-" + datemount + "-" + dateyear);
      return dateday + "-" + datemount + "-" + dateyear;
    },
    setdatePicker(_date) {
      let dateyear =
        _date.split("")[0] +
        _date.split("")[1] +
        _date.split("")[2] +
        _date.split("")[3];

      let datemount = _date.split("")[4] + _date.split("")[5];

      let dateday = _date.split("")[6] + _date.split("")[7];
      // this.setdatetime.push(dateday + "-" +datemount+ "-" + dateyear)
      console.log(dateday + "-" + datemount + "-" + dateyear);
      let datetime = dateyear + "-" + datemount + "-" + dateday;
      return (datetime !== "0000-00-00") ? datetime : "";
    },
    checkdatetime() {
      console.log(this.picker, this.hour, this.minute);
      if (this.picker !== "") {
        let dateyear =
          this.picker.split("")[0] +
          this.picker.split("")[1] +
          this.picker.split("")[2] +
          this.picker.split("")[3];

        let datemount = this.picker.split("")[5] + this.picker.split("")[6];
        let dateday = this.picker.split("")[8] + this.picker.split("")[9];
        this.dateymd = dateyear + datemount + dateday;
      } else {
        this.dateymd = "00000000";
      }
      if (this.hour !== "") {
        this.datehour = this.hour.split("")[0] + this.hour.split("")[1];
      } else {
        this.datehour = "00";
      }
      if (this.minute !== "") {
        this.dateminute = this.minute.split("")[0] + this.minute.split("")[1];
      } else {
        this.dateminute = "00";
      }
      let datesecond = "00";

      this.datetimecheck =
        this.dateymd + this.datehour + this.dateminute + datesecond;
      console.log("datetimecheck", this.datetimecheck);
    },
    async checktaxid() {
      if (this.taxid !== "") {
        this.checkdatetime();
        let auth = await gbfGenerate.generateToken();
        let payload;
        console.log(this.filedata.file_type);
        if (this.filedata.file_type === "folder") {
          payload = {
            id: this.filedata.file_id,
            account_id_sender: this.dataAccountId,
            taxid: [
              {
                reciever: this.taxid,
                status: this.status.key,
                date_time: this.datetimecheck,
              },
            ],
          };
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_SHARE_FOLDER +
                "/api/taxid/share_folder_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
                this.switch1 = false;
                this.picker = "";
                this.minute = "";
                this.hour = "";
                Toast.fire({
                  icon: "success",
                  title: this.$t("sharefile.sharefoldersuccess"),
                });
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                this.loaddatataxid();
              } else if (response.data.errorCode === "ER404") {
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharefile.notfoundtaxid"),
                });
              } else {
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
        } else {
          let auth = await gbfGenerate.generateToken();
          payload = {
            id: this.filedata.file_id,
            account_id_sender: this.dataAccountId,
            taxid: [
              {
                reciever: this.taxid,
                status: this.status.key,
                date_time: this.datetimecheck,
              },
            ],
          };
          console.log("payload", payload);
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_SHARE_FILE +
                "/api/taxid/share_file_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
                this.switch1 = false;
                this.picker = "";
                this.minute = "";
                this.hour = "";
                Toast.fire({
                  icon: "success",
                  title: this.$t("sharefile.sharefilesuccess"),
                });
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                this.loaddatataxid();
              } else if (response.data.errorCode === "ER404") {
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharefile.notfoundtaxid"),
                });
              } else {
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
        }
      } else {
        Toast.fire({
          icon: "warning",
          // title: "กรุณาระบุเลขประจำตัวผู้เสียภาษี(องกรณ์)ที่ต้องการแชร์"
          title: this.$t("sharefile.emptytaxid"),
        });
      }
    },
    async loaddatataxid(checkempty) {
      this.messagetax = [];
      this.statussharetax = [];
      this.pickertax = [];
      this.hourtax = [];
      this.minutetax = [];
      this.menutex = [];
      if (this.filedata.file_type === "folder") {
        let payload = {
          account_id_sender: this.dataAccountId,
          folder_id: this.filedata.file_id,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/taxid/get_sharewith_folder_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;
              console.log(response.data.message);
              this.messagetax = response.data.message;
              for (let i = 0; i < this.messagetax.length; i++) {
                let data = this.values.find(
                  (V) => V.key === this.messagetax[i]["status"]
                );
                let settime = this.messagetax[i].number_of_days;
                this.statussharetax.push(data);
                this.pickertax.push("");
                this.hourtax.push(this.settimehour(settime));
                this.minutetax.push(this.settimeminute(settime));
                this.menutex.push(false);
              }
              if (checkempty === true) {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
              }
            } else {
              this.loaddataprogress = true;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              // this.$emit("closesharefile");
            }
          });
      } else {
        let payload = {
          account_id_sender: this.dataAccountId,
          file_id: this.filedata.file_id,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FILE +
              "/api/taxid/get_sharewith_file_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;
              console.log(response.data.message);
              this.messagetax = response.data.message;
              for (let i = 0; i < this.messagetax.length; i++) {
                let data = this.values.find(
                  (V) => V.key === this.messagetax[i]["status"]
                );
                let settime = this.messagetax[i].number_of_days;
                this.statussharetax.push(data);
                this.pickertax.push("");
                this.hourtax.push(this.settimehour(settime));
                this.minutetax.push(this.settimeminute(settime));
                this.menutex.push(false);
              }
              if (checkempty === true) {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
              }
            } else {
              this.loaddataprogress = true;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              // this.$emit("closesharefile");
            }
          });
      }
    },
    async loaddata(checkempty) {
      this.group_mail_list = [];
      this.normol_mail_list = [];
      this.listgroup_data = [];
      this.message = [];
      this.statusshare = [];
      this.picker2 = [];
      this.hour2 = [];
      this.minute2 = [];
      this.menu2 = [];
      if (this.filedata.file_type === "folder") {
        let getdata = {
          account_id_sender: this.dataAccountId,
          folder_id: this.filedata.file_id,
        };
        let auth = await gbfGenerate.generateToken();

        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/get_sharewith_folder_v4",
            getdata,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;
              // console.log("laaaaaa",response.data.message);
              this.group_mail_list = response.data.group_data;
              this.normol_mail_list = response.data.normal_data;

              console.log("group_mail_list", this.group_mail_list);
              console.log("normol_mail_list", this.normol_mail_list);
              // this.message = response.data.message;
              // console.log(response.data);
              for (let i = 0; i < this.normol_mail_list.length; i++) {
                // this.listgroup_data_normal.push(this.normol_mail_list[i])
                this.group_mail_list.push({
                  group_mail: this.normol_mail_list[i],
                });
              }
              console.log("=====", this.group_mail_list);
              for (let i = 0; i < this.group_mail_list.length; i++) {
                if(this.group_mail_list[i]["group_mail"].status === 'DE' || this.group_mail_list[i]["group_mail"].status === 'De'){
                  this.group_mail_list[i]["group_mail"].status = 'De'
                }
                console.log(
                  "***",
                  this.group_mail_list[i]["group_mail"].number_of_days
                );
                let data = this.values.find(
                  (V) =>
                    V.key === this.group_mail_list[i]["group_mail"]["status"]
                );
                // let data_mail = this.values.find((V) => V.key ===  this.group_mail_list[i]["mail_list"]["status"]);
                let settime = this.group_mail_list[i]["group_mail"]
                  .number_of_days;
                this.statusshare.push(data);
                // this.statusshare_.push(data_mail);
                this.picker2.push(this.setdatePicker(settime));
                this.hour2.push(this.settimehour(settime));
                this.minute2.push(this.settimeminute(settime));
                this.menu2.push(false);
                // let date = this.message[i]["number_of_days"]
                //  this.setdatetime.push(settime)

                this.listgroup_data.push(this.group_mail_list[i]);
              }
              if (checkempty === true) {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
              }
              console.log("listgroup_data", this.listgroup_data);

              // for (let i = 0; i < this.group_mail_list.length; i++) {

              // }

              // for (let i = 0; i < this.group_mail_list.length; i++) {
              //   let data = this.values.find((V) => V.key === this.group_mail_list[i]["status"]);
              //   let settime = this.group_mail_list[i].number_of_days
              //   this.statusshare.push(data);
              //   this.picker2.push("");
              //   this.hour2.push(this.settimehour(settime));
              //   this.minute2.push(this.settimeminute(settime));
              //   this.menu2.push(false);
              //   //  let date = this.message[i]["number_of_days"]
              //   //  this.setdatetime.push(date)
              // }

              // for (let i = 0; i < this.message.length; i++) {
              //   let data_ = this.values.find((V) => V.key === this.message[i]["status"]);
              //   console.log("data_",data_);
              //   let settime_ = this.message[i].number_of_days
              //   this.statusshare_.push(data_);
              //   this.picker2.push("");
              //   this.hour2.push(this.settimehour(settime_));
              //   this.minute2.push(this.settimeminute(settime_));
              //   this.menu2.push(false);

              //   //  let date = this.message[i]["number_of_days"]
              //   //  this.setdatetime.push(date)
              // }
            } else {
              this.loaddataprogress = true;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              // this.$emit("closesharefile");
            }
          });
      } else {
        let getdata = {
          account_id_sender: this.dataAccountId,
          file_id: this.filedata.file_id,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FILE +
              "/api/get_sharewith_file_v4",
            getdata,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.loaddataprogress = true;

              this.group_mail_list = response.data.group_data;
              this.normol_mail_list = response.data.normal_data;
              this.list_sharefile = response.data.normal_data

              console.log("group_mail_list", this.group_mail_list);
              console.log("normol_mail_list", this.normol_mail_list);
              console.log("list_sharefile", this.list_sharefile);
              // this.message = response.data.message;
              console.log(response.data);
              // for (let i = 0; i < this.message.length; i++) {
              //   let data = this.values.find((V) => V.key === this.message[i]["status"]);
              //   let settime = this.message[i].number_of_days
              //   this.statusshare.push(data);
              //   this.picker2.push("");
              //   this.hour2.push(this.settimehour(settime));
              //   this.minute2.push(this.settimeminute(settime));
              //   this.menu2.push(false);
              //   // let date = this.message[i]["number_of_days"]
              //   //  this.setdatetime.push(date)
              // }
              for (let i = 0; i < this.normol_mail_list.length; i++) {
                // this.listgroup_data_normal.push(this.normol_mail_list[i])
                this.group_mail_list.push({
                  group_mail: this.normol_mail_list[i],
                });
              }
              console.log("=====", this.group_mail_list);
              for (let i = 0; i < this.group_mail_list.length; i++) {
                if(this.group_mail_list[i]["group_mail"].status === 'DE' || this.group_mail_list[i]["group_mail"].status === 'De'){
                  this.group_mail_list[i]["group_mail"].status = 'De'
                }
                console.log(
                  "***",
                  this.group_mail_list[i]["group_mail"].number_of_days
                );
                let data = this.values.find(
                  (V) =>
                    V.key === this.group_mail_list[i]["group_mail"]["status"]
                );
                // let data_mail = this.values.find((V) => V.key ===  this.group_mail_list[i]["mail_list"]["status"]);
                let settime = this.group_mail_list[i]["group_mail"]
                  .number_of_days;
                this.statusshare.push(data);
                // this.statusshare_.push(data_mail);
                this.picker2.push(this.setdatePicker(settime));
                this.hour2.push(this.settimehour(settime));
                this.minute2.push(this.settimeminute(settime));
                this.menu2.push(false);
                // let date = this.message[i]["number_of_days"]
                //  this.setdatetime.push(settime)

                this.listgroup_data.push(this.group_mail_list[i]);
              }
              if (checkempty === true) {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
              }
            } else {
              this.loaddataprogress = true;
              console.log(response.data.errorMessage);
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              // this.$emit("closesharefile");
            }
          });
      }
    },
    closevalues() {
      this.email = "";
      this.statusshare = [];
      this.statussharetax = [];
      this.status = { name: "view", key: "V", text: "sharefile.view" };
    },
    //  on-button
    async addemailapi(adddata, id, list_id) {
      // let adddata = [];
      //console.log("nnnnn",this.emailadd);
      //for (let i = 0; i < this.emailadd.length; i++) {
      //console.log("LLLLL",this.emailadd[i].id,this.emailadd[i].status.key);
      // adddata.push({
      //   reciever:_id,
      //   status: _key,
      //   date_time: this.datetimecheck,
      //   group_mail:"",
      //   name_shared:"",
      //   type_shared:""
      // });
      // }
      let payload = {
        id: this.filedata.file_id,
        account_id_sender: this.dataAccountId,
        account_id_reciever: adddata,
      };
      console.log("payload addemailapi", payload);
      if (this.filedata.file_type === "folder") {
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_folder_v4",
          data: payload,
          headers: { Authorization: auth.code },
        });
        return new Promise((resolve, reject) => {
          try {
            if (response.data.status === "OK") {
              this.emailadd = [];
              this.statusshare = [];
              this.switch1 = false;
              this.hour = "";
              this.minute = "";
              this.picker = "";

              setTimeout(() => {
                resolve({ status: "success", msg: "OK", code: "" });
              }, 500);
            } else {
              this.picker = "";
              this.hour = "";
              this.minute = "";
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              setTimeout(() => {
                resolve({ status: "error", msg: "OK", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "denied", msg: ": " + err, code: "" });
            }, 500);
          }
        });
        // let auth = await gbfGenerate.generateToken();
        // if (this.filedata.file_type === "folder")
        //   this.axios
        //     .post(process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_folder_v4", payload, {
        //       headers: { Authorization: auth.code },
        //     })
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.emailadd = [];
        //         this.statusshare = [];
        //         this.switch1 = false;
        //         this.hour = "";
        //         this.minute = "";
        //         this.picker = "";
        //         // this.loaddata();
        //         Toast.fire({
        //           icon: "success",
        //           title: this.$t("sharefile.sharefoldersuccess"),
        //         });
        //         setTimeout(() => {
        //               resolve({ status: "success", msg: "OK", code: "" });
        //                 }, 500);
        //       } else {
        //         this.picker = "";
        //         this.hour = "";
        //         this.minute = "";
        //         Toast.fire({
        //           icon: "error",
        //           title: response.data.errorMessage
        //         });
        //         setTimeout(() => {
        //               resolve({ status: "error", msg: "OK", code: "" });
        //                 }, 500);
        //       }
        //     });
      } else {
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_SHARE_FILE + "/api/share_file_v4",
          data: payload,
          headers: { Authorization: auth.code },
        });
        return new Promise((resolve, reject) => {
          try {
            if (response.data.status === "OK") {
              this.emailadd = [];
              this.statusshare = [];
              this.hour = "";
              this.minute = "";
              this.picker = "";
              this.switch1 = false;

              setTimeout(() => {
                resolve({ status: "success", msg: "OK", code: "" });
              }, 500);
            } else {
              this.hour = "";
              this.minute = "";
              this.picker = "";
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              setTimeout(() => {
                resolve({ status: "error", msg: "OK", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "denied", msg: ": " + err, code: "" });
            }, 500);
          }
        });
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_SHARE_FILE + "/api/share_file_v4", payload, { headers: { Authorization: auth.code } })
        //   .then((response) => {
        //     if (response.data.status === "OK") {
        //       this.emailadd = [];
        //       this.statusshare = [];
        //       this.hour = "";
        //       this.minute = "";
        //       this.picker = "";
        //       this.switch1 = false;
        //       // this.loaddata();
        //       Toast.fire({
        //         icon: "success",
        //         title: this.$t("sharefile.sharefilesuccess"),
        //       });
        //       setTimeout(() => {
        //             resolve({ status: "success", msg: "OK", code: "" });
        //               }, 500);
        //     } else {
        //       this.hour = "";
        //       this.minute = "";
        //       this.picker = "";
        //       Toast.fire({
        //         icon: "error",
        //         title: response.data.errorMessage
        //       });
        //       setTimeout(() => {
        //             resolve({ status: "error", msg: "OK", code: "" });
        //               }, 500);
        //     }
        //   });
      }
    },
    // button share
    async addemail() {
      this.loaddataprogress = false;
      if (this.typeshare_data !== "") {
        let payload = {
          data_type: this.typeshare,
          data_text: this.typeshare_data,
        };
        let i;
        let dflag = this.message.findIndex(
          (v) => v.data_text === this.typeshare_data
        );
        if (dflag == -1) {
          let auth = await gbfGenerate.generateToken();
          this.checkdatetime();
          this.axios
            // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v2/check_account_bymail", payload, {
            //   headers: { Authorization: auth.code },
            // })
            // apiใหม่
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/v1/check_account_oneid",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (this.typeshare_data == "") {
                Toast.fire({
                  icon: "warning",
                  title: this.$t("sharefile.pleasefill_email"),
                });
              } else if (response.data.status === "OK") {
                if (this.statusshare.length === 0 && this.statussharetax.length === 0) {
                  this.statusclosereload = true;
                }
                console.log("==res", response.data.data);
                this.list_id = response.data.data;
                this.fn_loopshare(this.list_id);
                // this.id = response.data.result["id"];
                // this.emailadd.push({
                //   id: this.id,
                //   email: this.email,
                //   status: this.status,
                // });
                /////////////
                //  let adddata = [];
                //   for(let i=0; i < this.list_id.length; i++){
                //     adddata.push({
                //     reciever: this.list_id[i].id,
                //     status: this.status.key,
                //     date_time: this.datetimecheck,
                //     group_mail: this.list_id[i].group_mail,
                //     name_shared:this.list_id[i].name_shared,
                //     type_shared:this.list_id[i].type_shared
                //   });
                //   console.log("adddata",adddata);
                //   }

                //   // this.addemailapi(this.id,this.status.key,);
                //    await this.addemailapi(adddata, this.list_id[i].id).then(async (res) => {
                //     if(res.status === "delete success"){
                //       console.log("yess");
                //     }else{
                //       console.log("nooo");
                //     }
                //   });
                //   this.typeshare_data = "";
                //   this.status = { name: "view", key: "V", text: "sharefile.view" };
              } else if (response.data.errorCode === "ER400") {
                this.loaddataprogress = true;
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharefile.notfoundemail"),
                });
              } else {
                this.loaddataprogress = true;
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
        } else {
          this.loaddataprogress = true;
          Toast.fire({
            icon: "error",
            title: this.$t("sharefile.cannotemail"),
          });
        }
      } else {
        this.loaddataprogress = true;
        if (this.typeshare === "thai_email") {
          Toast.fire({
            icon: "warning",
            // title: "กรุณากรอกอีเมล"
            title: this.$t("sharefile.pleasefill_email"),
          });
        } else if (this.typeshare === "username") {
          Toast.fire({
            icon: "warning",
            // title: "กรุณากรอกชื่อผู้ใช้งาน"
            title: this.$t("sharefile.pleasefill_username"),
          });
        } else if (this.typeshare === "one_collab_mail") {
          Toast.fire({
            icon: "warning",
            // title: "กรุณากรอก one_collab"
            title: this.$t("sharefile.pleasefill_onecollab"),
          });
        } else {
          Toast.fire({
            icon: "warning",
            // title: "กรุณาเลือกประเภทผู้ใช้งาน"
            title: this.$t("sharefile.pleasefill_type"),
          });
        }
      }
    },
    async fn_loopshare(list_id) {
      console.log("list_id fn_loopshare", list_id);
      let adddata = [];
      for (let i = 0; i < list_id.length; i++) {
        adddata.push({
          reciever: list_id[i].id,
          status: this.status.key,
          date_time: this.datetimecheck,
          group_mail: list_id[i].group_mail,
          name_shared: list_id[i].name_shared,
          type_shared: list_id[i].type_shared,
        });
        console.log("adddata", adddata);
        await this.addemailapi(adddata, list_id[i].id, list_id).then(
          async (res) => {
            if (res.status === "success") {
              adddata = [];
              console.log("yess");
              if (list_id.length - i === 1) {
                this.loaddataprogress = true;
                this.loaddata();
                Toast.fire({
                  icon: "success",
                  title:
                    list_id[i].group_mail +
                    this.$t("sharefile.sharefoldersuccess"),
                });
              }
            } else {
              this.loaddataprogress = true;
              console.log("nooo");
              adddata = [];
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        );
      }

      this.typeshare_data = "";
      this.status = { name: "view", key: "V", text: "sharefile.view" };
    },
    // Delete data
    async deletemessagetax(item) {
      console.log("deltax", item);
      let datachild = [];
      datachild.push({ taxid: item.taxid });
      let payload = {
        id: this.filedata.file_id,
        account_id: this.dataAccountId,
        remove: datachild,
      };
      let auth = await gbfGenerate.generateToken();
      console.log(this.filedata.file_type);
      if (this.filedata.file_type === "folder") {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/taxid/delete_sharing_folder_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddatataxid(true);
              Toast.fire({
                icon: "error",
                title: this.$t("sharefile.deletesuc"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      } else {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FILE +
              "/api/taxid/delete_sharing_file_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddatataxid(true);
              Toast.fire({
                icon: "error",
                title: this.$t("sharefile.deletesuc"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },
    async deletemessage(item) {
      console.log("-------", item);
      let datachild = [];
      datachild.push({
        child:
          item.account_id_reciever !== undefined
            ? item.account_id_reciever
            : "",
        group_mail:
          item.group_mail !== undefined ? item.group_mail : item.name_th,
        type_shared: item.type_shared,
      });
      let payload = {
        id: this.filedata.file_id,
        account_id: this.dataAccountId,
        remove: datachild,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      if (this.filedata.file_type === "folder") {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FOLDER +
              "/api/delete_sharing_folder_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddata(true);
              Toast.fire({
                icon: "success",
                title: this.$t("sharefile.deletesuc"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      } else {
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_SHARE_FILE +
              "/api/delete_sharing_file_v4",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.status === "OK") {
              this.loaddata(true);
              Toast.fire({
                icon: "success",
                title: this.$t("sharefile.deletesuc"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },
    deldatatax(item) {
      Swal.fire({
        title: "คุณต้องการลบ?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ใช่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletemessagetax(item);
        }
      });
    },
    deldata(item) {
      Swal.fire({
        title: "คุณต้องการลบ?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ใช่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletemessage(item);
        }
      });
    },
    formatdatetextfield(_date) {
      // console.log("test date", _date);
      if (_date === "" || _date === null || _date === undefined) {
        return "";
      } else {
        let dateyear =
          _date.split("")[0] +
          _date.split("")[1] +
          _date.split("")[2] +
          _date.split("")[3];
        let datemount = _date.split("")[5] + _date.split("")[6];
        let dateday = _date.split("")[8] + _date.split("")[9];
        return dateday + "-" + datemount + "-" + dateyear;
      }
    },
    formatdate(_date) {
      console.log("format1-----------", _date);
      if (_date === "" || _date === null || _date === undefined) {
        return "";
      } else {
        let dateyear =
          _date.split("")[0] +
          _date.split("")[1] +
          _date.split("")[2] +
          _date.split("")[3];

        let datemount = _date.split("")[5] + _date.split("")[6];

        let dateday = _date.split("")[8] + _date.split("")[9];
        // this.setdatetime.push(dateday + "-" +datemount+ "-" + dateyear)
        console.log("format2-----------------", dateyear + datemount + dateday);

        return dateyear + datemount + dateday;
      }
    },
    formathour(_hour) {
      console.log("_hour", _hour);
      if (_hour === "") {
        this.sethour = "";
      } else {
        this.sethour = _hour.split("")[0] + _hour.split("")[1];
      }
      console.log("_hour2", this.sethour);
      return this.sethour;
    },
    formatminute(_minute) {
      console.log("_minute", _minute);
      if (_minute === "") {
        this.setminute = "";
      } else {
        this.setminute = _minute.split("")[0] + _minute.split("")[1];
      }
      console.log("_minute2", this.setminute);
      return this.setminute;
    },
    // button updates
    async editapi() {
      if (this.opentabs === 0) {
        let dataarray = [];
        this.processloading = true;
        console.log("listgroup_data==", this.listgroup_data);
        console.log("listgroup_data==", this.listgroup_data.group_mail);

        for (let i = 0; i < this.listgroup_data.length; i++) {
          // checkdate
          if (this.formatdate(this.picker2[i]) === "") {
            this.editdate = this.listgroup_data[
              i
            ].group_mail.number_of_days.slice(0, 8);
            // this.editdate = this.message[i].number_of_days.slice(0, 8);
          } else {
            this.editdate = this.formatdate(this.picker2[i]);
          }
          //  checkhour
          if (this.formathour(this.hour2[i]) === "") {
            this.edithour = this.listgroup_data[
              i
            ].group_mail.number_of_days.slice(8, 10);
          } else {
            this.edithour = this.formathour(this.hour2[i]);
          }
          // checkmin
          if (this.formatminute(this.minute2[i]) === "") {
            this.editmin = this.listgroup_data[
              i
            ].group_mail.number_of_days.slice(10, 12);
          } else {
            this.editmin = this.formatminute(this.minute2[i]);
          }
          console.log(
            "-----------------",
            this.editdate + this.edithour + this.editmin
          );
          console.log("-group_mail--", this.listgroup_data[i].group_mail);
          dataarray.push({
            child:
              this.listgroup_data[i].group_mail.type_shared !== "3"
                ? this.listgroup_data[i].group_mail.account_id_reciever
                : "",
            status: this.statusshare[i].key,
            date_time: this.editdate + this.edithour + this.editmin + "00",
            group_mail:
              this.listgroup_data[i].group_mail.type_shared === "3"
                ? this.listgroup_data[i].group_mail.name_th
                : "",
          });
        }
        let payload = {
          id: this.filedata.file_id,
          account_id: this.dataAccountId,
          array: dataarray,
        };
        if (this.filedata.file_type === "folder") {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/update_folder_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        } else {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/update_file_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        }
      } else {
        this.processloading = true;
        let dataarray = [];
        for (let i = 0; i < this.messagetax.length; i++) {
          console.log(
            "this.messagetax[i].number_of_days.slice(0,8)",
            this.messagetax[i].number_of_days.slice(0, 8)
          );
          console.log(
            "this.formatdate(this.pickertax[i]) ใหม่",
            this.formatdate(this.pickertax[i])
          );
          // checkdate
          if (this.formatdate(this.pickertax[i]) === "") {
            this.editdate = this.messagetax[i].number_of_days.slice(0, 8);
          } else {
            this.editdate = this.formatdate(this.pickertax[i]);
          }
          //  checkhour
          if (this.formathour(this.hourtax[i]) === "") {
            this.edithour = this.messagetax[i].number_of_days.slice(8, 10);
          } else {
            this.edithour = this.formathour(this.hourtax[i]);
          }
          // checkmin
          if (this.formatminute(this.minutetax[i]) === "") {
            this.editmin = this.messagetax[i].number_of_days.slice(10, 12);
          } else {
            this.editmin = this.formatminute(this.minutetax[i]);
          }
          dataarray.push({
            taxid: this.messagetax[i].taxid,
            status: this.statussharetax[i].key,
            date_time: this.editdate + this.edithour + this.editmin + "00",
          });
        }
        let payload = {
          id: this.filedata.file_id,
          account_id: this.dataAccountId,
          taxid: dataarray,
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payloadtax", payload);
        if (this.filedata.file_type === "folder") {
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/taxid/update_folder_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        } else {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE +
                "/api/taxid/update_file_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        }
      }
    },
    async edit_share_detail() {
      if (this.opentabs === 0) {
        let dataarray = [];
        this.processloading = true;
        console.log("listgroup_data==", this.listgroup_data);
        console.log("listgroup_data==", this.listgroup_data.group_mail);

        for (let i = 0; i < this.listgroup_data.length; i++) {
          console.log("picker2",this.picker2[i]);
          console.log("picker2",this.formatdate(this.picker2[i]));
          // checkdate
          if (this.formatdate(this.picker2[i]) === "") {
            // this.editdate = this.listgroup_data[
            //   i
            // ].group_mail.number_of_days.slice(0, 8);
            this.editdate = "00000000"
            this.hour2[i] = "00"
            this.minute2[i] = "00"
            // this.editdate = this.message[i].number_of_days.slice(0, 8);
          } else {
            this.editdate = this.formatdate(this.picker2[i]);
          }
          //  checkhour
          if (this.formathour(this.hour2[i]) === "") {
            this.edithour = this.listgroup_data[
              i
            ].group_mail.number_of_days.slice(8, 10);
          } else {
            this.edithour = this.formathour(this.hour2[i]);
          }
          // checkmin
          if (this.formatminute(this.minute2[i]) === "") {
            this.editmin = this.listgroup_data[
              i
            ].group_mail.number_of_days.slice(10, 12);
          } else {
            this.editmin = this.formatminute(this.minute2[i]);
          }
          console.log(
            "-----------------",
            this.editdate + this.edithour + this.editmin
          );
          console.log("-group_mail--", this.listgroup_data[i].group_mail);
          dataarray.push({
            child:
              this.listgroup_data[i].group_mail.type_shared !== "3"
                ? this.listgroup_data[i].group_mail.account_id_reciever
                : "",
            status: this.statusshare[i].key,
            date_time: this.editdate + this.edithour + this.editmin + "00",
            group_mail:
              this.listgroup_data[i].group_mail.type_shared === "3"
                ? this.listgroup_data[i].group_mail.name_th
                : "",
          });
        }
        let payload = {
          id: this.filedata.file_id,
          account_id: this.dataAccountId,
          array: dataarray,
        };
        if (this.filedata.file_type === "folder") {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/update_folder_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.edit_detail = false;
                this.email = "";
                // this.hour2 = [];
                // this.minute2 = [];
                // this.picker2 = [];
                // this.picker = "";
                // this.minute = "";
                // this.hour = "";
                this.switch1 = false;
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "success",
                  title:"บันทึกข้อมูลสำเร็จ",
                });
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        } else {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/update_file_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                // this.$emit("closesharefile");
                // this.sharefile_setting = false;
                this.email = "";
                // this.hour2 = [];
                // this.minute2 = [];
                // this.picker2 = [];
                // this.picker = "";
                // this.minute = "";
                // this.hour = "";
                this.switch1 = false;
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "success",
                  title:"บันทึกข้อมูลสำเร็จ",
                });
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.email = "";
                this.hour2 = [];
                this.minute2 = [];
                this.picker2 = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        }
      } else {
        this.processloading = true;
        let dataarray = [];
        for (let i = 0; i < this.messagetax.length; i++) {
          console.log(
            "this.messagetax[i].number_of_days.slice(0,8)",
            this.messagetax[i].number_of_days.slice(0, 8)
          );
          console.log(
            "this.formatdate(this.pickertax[i]) ใหม่",
            this.formatdate(this.pickertax[i])
          );
          // checkdate
          if (this.formatdate(this.pickertax[i]) === "") {
            // this.editdate = this.messagetax[i].number_of_days.slice(0, 8);
            this.editdate = "00000000"
            this.hourtax[i] = "00"
            this.minutetax[i] = "00"
          } else {
            this.editdate = this.formatdate(this.pickertax[i]);
          }
          //  checkhour
          if (this.formathour(this.hourtax[i]) === "") {
            this.edithour = this.messagetax[i].number_of_days.slice(8, 10);
          } else {
            this.edithour = this.formathour(this.hourtax[i]);
          }
          // checkmin
          if (this.formatminute(this.minutetax[i]) === "") {
            this.editmin = this.messagetax[i].number_of_days.slice(10, 12);
          } else {
            this.editmin = this.formatminute(this.minutetax[i]);
          }
          dataarray.push({
            taxid: this.messagetax[i].taxid,
            status: this.statussharetax[i].key,
            date_time: this.editdate + this.edithour + this.editmin + "00",
          });
        }
        let payload = {
          id: this.filedata.file_id,
          account_id: this.dataAccountId,
          taxid: dataarray,
        };
        let auth = await gbfGenerate.generateToken();
        console.log("payloadtax", payload);
        if (this.filedata.file_type === "folder") {
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/taxid/update_folder_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.edit_detail = false;
                // this.hourtax = [];
                // this.minutetax = [];
                // this.pickertax = [];
                // this.picker = "";
                // this.minute = "";
                // this.hour = "";
                this.switch1 = false;
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "success",
                  title:"บันทึกข้อมูลสำเร็จ",
                });
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        } else {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE +
                "/api/taxid/update_file_v4",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.processloading = false;
                // this.$emit("closesharefile");
                // this.sharefile_setting_tax = false;
                // this.hourtax = [];
                // this.minutetax = [];
                // this.pickertax = [];
                // this.picker = "";
                // this.minute = "";
                // this.hour = "";
                this.switch1 = false;
                this.taxid = "";
                this.status = {
                  name: "view",
                  key: "V",
                  text: "sharefile.view",
                };
                Toast.fire({
                  icon: "success",
                  title:"บันทึกข้อมูลสำเร็จ",
                });
              } else {
                this.processloading = false;
                // this.$emit("closesharefile");
                this.hourtax = [];
                this.minutetax = [];
                this.pickertax = [];
                this.picker = "";
                this.minute = "";
                this.hour = "";
                this.switch1 = false;
                console.log(response.data.errorMessage);
                Toast.fire({
                  icon: "error",
                  title:
                    response.data.errorCode + ": " + response.data.errorMessage,
                });
              }
            });
        }
      }
    },
  },
};
</script>
<style>
#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.scroller {
  height: 300px;
  overflow-y: scroll;
  /* scrollbar-color: rebeccapurple green;
  scrollbar-width: thin; */
  overflow-x: hidden;
}
</style>
